
import { useUsersStore } from '@/stores/users';
import { helpers } from '@vuelidate/validators'

export const uniqueUsername =  (currentUsername) => {
    return helpers.withMessage('Username already in use', function(username)  {
        if(!username || username === '' )  {
            return false
        }
        const usersStore = useUsersStore()
    return usersStore.$state.users.findIndex(user => (user.username.toLowerCase() == username.toLowerCase() )&& (user.username.toLowerCase() != currentUsername.toLowerCase())) === -1
    })
}
