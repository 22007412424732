
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';

import { cloneDeep, isEqual } from "lodash";
import { useAssetsStore } from './assets';
import { useUserStore } from './user';
import { useFilesStore } from './files';

import { v4 as uuidv4 } from 'uuid';



const initialState = (uuidv4) => { return {
    'id': uuidv4,
    'path': null,
    'base64': null,
    'file_name': null,
    'file_type': null,
    'updated_at': null,
}}

export const useFileStore = defineStore('file', {
    state: () => ( cloneDeep(initialState(uuidv4()))),
    actions: {
        storeFilesFromEvent(event, store) {
            const filesStore =   useFilesStore();

            for (let file of event.target.files) {
                let currentState = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    var img = new Image(); //create a image
                    img.src = e.target.result; //result is base64-encoded Data URI
                    img.size = e.target.size; //set size (optional)
                    img.onload = function (el) {
                        currentState.$reset()
                        currentState.file_type = file.type
                        currentState.file_name = file.name

                        var elem = document.createElement("canvas"); //create a canvas
                        //scale the image to 600 (width) and keep aspect ratio
                        var scaleFactor = 1000 / el.target.width;
                        elem.width = 1000;
                        elem.height = el.target.height * scaleFactor;
                        //draw in canvas
                        var ctx = elem.getContext("2d");
                        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);
                        //get the base64-encoded Data URI from the resize image
                        var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 1);
                        currentState.base64 = srcEncoded
                        filesStore.saveFile(cloneDeep(currentState.$state))
                        store.file_id = currentState.id
                        initialState.id = uuidv4()
                        currentState.$patch(initialState);
                      };

                };
                reader.onerror = function (error) {
                  console.error('Error: ', error);
                };
            }
            // store.$state.location = 'test';
        },
        storeFile(file, store) {
            const filesStore =   useFilesStore();
            let currentState = this
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                const img = new Image(); //create a image
                img.src = e.target.result; //result is base64-encoded Data URI
                img.onload = function (el) {
                    currentState.$reset()
                    currentState.$state.file_type = file.type
                    currentState.$state.file_name = file.name

                    const elem = document.createElement("canvas"); //create a canvas
                    //scale the image to 600 (width) and keep aspect ratio
                    var scaleFactor = 1000 / el.target.width;
                    elem.width = 1000;
                    elem.height = el.target.height * scaleFactor;
                    //draw in canvas
                    var ctx = elem.getContext("2d");
                    ctx.drawImage(el.target, 0, 0, elem.width, elem.height);
                    //get the base64-encoded Data URI from the resize image
                    var srcEncoded = ctx.canvas.toDataURL("image/jpeg", 1);
                    currentState.$state.base64 = srcEncoded
                    filesStore.saveFile(cloneDeep(currentState.$state))
                    // store.file_id = currentState.$state.id
                    store.answer.value = currentState.$state.id
                    store.answerStore.saveAnswer(store.question_answer_type);
                    initialState.id = uuidv4()
                    currentState.$state = initialState(uuidv4());
                }
            };
            reader.onerror = function (error) {
                console.error('Error: ', error);
            };
        },
    },
})

