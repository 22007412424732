<template>
  <v-bottom-navigation  :density="'comfortable'"  class="justify-start " :class="showPurpleBanner ? 'bg-primaryRed' : 'bg-dark'">


    <v-btn v-if="userStore.isAdmin()" :to="{ name: 'Users'}">
      <v-icon>fas fa-users</v-icon>

      <span>Users</span>
    </v-btn>

    <v-btn v-if="userStore.isAdmin()" :to="{ name: 'Sites'}">
      <v-icon>fas fa-location-dot</v-icon>

      <span>Sites</span>
    </v-btn>

    <v-btn  :to="{ name: 'Inspections'}">
      <v-icon>fas fa-clipboard-list-check</v-icon>

      <span>Inspections</span>
    </v-btn>
    <v-btn @click="myAssetSheet = !myAssetSheet">
      <v-icon>fas fa-fire-extinguisher</v-icon>

      <span>Assets</span>
    </v-btn>
    <v-btn @click="myAccountSheet = !myAccountSheet">
      <v-icon>fas fa-user</v-icon>

      <span>Account</span>
    </v-btn>


  </v-bottom-navigation>
   <v-bottom-sheet v-model="myAccountSheet">
        <v-card
          class="text-center"
        >
          <v-card-text>
            <v-row>

              <v-col cols="6">
                <v-btn block :to="{name: 'My-Account'}"  prepend-icon="fas fa-user">
                  My Account
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="userStore.logout()" prepend-icon="fas fa-right-to-bracket">
                  <span>Log Out</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>

    <v-bottom-sheet v-model="myAssetSheet" :height="showFindAsset ? '50vh' : ''" @update:modelValue="showFindAsset = false">
        <v-card color="#EAEAEA" height="100%">
            <v-card-text>
                <v-row v-if="!showFindAsset">
                    <v-col cols="12">
                        <find-asset></find-asset>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block @click="showFindAsset = true" prepend-icon="fas fa-search">Find Asset</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block @click="myAssetSheet = false" :to="{name:'Asset-Create'}" prepend-icon="fas fa-circle-plus">Add Asset</v-btn>
                    </v-col>
                </v-row>
                <template v-if="showFindAsset">
                <FindAssetBottomSheetVue v-model="myAssetSheet" @update:modelValue="showFindAsset = false"></FindAssetBottomSheetVue>
                </template>
            </v-card-text>
        </v-card>

    </v-bottom-sheet>

</template>

<script setup>
import { ref } from 'vue';
import { useUserStore } from '@/stores/user.js';
import FindAssetBottomSheetVue from '../../components/FindAssetBottomSheet.vue';
import FindAsset from '../../components/FindAsset.vue';
const userStore = useUserStore();
const myAccountSheet = ref(false);
const myAssetSheet = ref(false);
const showFindAsset = ref(false);
const showPurpleBanner = import.meta.env.VITE_BANNER_STAGING ? true : false;
</script>

<style>
.v-bottom-navigation {
    height: 100px !important;
}

.v-bottom-navigation__content {
    padding-bottom: 40px ;
}
</style>
