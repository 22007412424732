<script setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue'
import { useSitesStore } from '../stores/sites.js';
import { useAssetStore } from '../stores/asset.js';
import { useAssetsStore } from '../stores/assets.js';
import { useAssetGradesStore } from '../stores/AssetGrades.js';

import { useRoute , useRouter} from "vue-router";
import moment from 'moment';
import { useInspectionsStore } from '../stores/inspections.js';
import IncompleteInspectionGrading from '@/components/IncompleteInspectionGrading.vue'
import GradingOptions from '@/components/GradingOptions.vue'

const route = useRoute()
const router = useRouter();
const assetsStore = useAssetsStore()
const assetStore = useAssetStore()
const assetGradesStore = useAssetGradesStore()
const inspectionsStore = useInspectionsStore()
const sitesStore = useSitesStore()

const asset = storeToRefs(assetStore)
const {id, history, site_id, location, serial_number, asset_type, asset_type_id} =  storeToRefs(assetStore)
const historyToggle = ref(false)
const replaceToggle = ref(false)
const site = sitesStore.getSiteById(asset.site_id.value)

const assetGrades = computed(() => {return assetGradesStore.getAssetGradesByAssetId(asset.id.value)})
const assetGradesByAssetId = computed(() =>  {return assetGradesStore.getAssetGradesByAssetId(asset.id.value)})
const latestAssetGrade = computed( () => { return assetGradesStore.getLatestAssetGradeForAssetId(asset.id.value)})
const gradings =  computed(() => { return [...assetGradesStore.getAssetGradesByAssetId(asset.id.value), ...inspectionsStore.getsInspectionsByAssetWithoutSurvey(asset.id.value)].sort((a,b) => {
        let aCol = ''
        let bCol = ''
        if('graded_at' in a ) {
            aCol = 'graded_at'
        }
        if('graded_at' in b ) {
            bCol = 'graded_at'
        }
        if('date_from' in a ) {
            aCol = 'date_from'
        }
        if('date_from' in b ) {
            bCol = 'date_from'
        }

        return b[bCol].localeCompare(a[aCol])
    })
});
const inspections = computed(() => {return inspectionsStore.getsInspectionsByAssetWithoutSurvey(asset.id.value)})
const activeInspection = computed( () => {return inspectionsStore.getCurrentInspectionByAssetId(asset.id.value)})

const assetGradeText = function(assetGrade) {
    if(!assetGrade.inspection_id && assetGrade.answers?.length) {
        return "Ad Hoc Survey"
    }
    if(assetGrade.answers?.length) {
        let text = "Completed inspection"
        const inspection = inspectionsStore.getInspectionById(assetGrade.inspection_id);
        if(inspection?.title)  {
            text = text + ' - ' + inspection.title
        }
        return text
    }

    if(assetGrade.graded_at && !assetGrade.answers?.length)
    {
        return "Grade Change"
    }
}
const assetGradeIcon = function (assetGrade) {
    const inspection = inspectionsStore.getInspectionById(assetGrade.inspection_id);
    if(inspection?.closed_at) {
        return "fa-eye";
    }

    return "fa-pencil";
}
const viewSurvey = function (asset_grade_id) {
    const asset_grade = assetGradesStore.getAssetGradeById(asset_grade_id);
    router.push({name: "Asset-Grade-Survey-Answers", params:{asset_id: asset.id.value, inspection_id: asset_grade.inspection_id, asset_grade_id: asset_grade_id}})

}
const checkIfActiveInspection = function() {
    let isActiveInspection = false
    gradings.value.forEach(grading => {
        isActiveInspection = 'date_from' in grading
    });
    return isActiveInspection;
}

const formatRecommendations = (recommendations) => {
    let safeText = recommendations
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");

      safeText = safeText.replace(/\n/g, "<br>");

      return safeText;
    }


</script>

<template>
    <v-row>
        <v-col cols="12" class="mt-3">
                <v-card :title="asset_type?.name">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-hashtag"></v-icon-primary-red><span>{{ serial_number ?? 'NO SERIAL' }}</span></v-col>
                            <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-location-dot"></v-icon-primary-red><div><p>{{ site?.name }}</p><p>{{location}}</p></div></v-col>
                            <v-col cols="6">
                                <v-btn-outline block :to="{name:'Asset-Edit', params: {asset_id: id}}" prepend-icon="far fa-edit">Edit</v-btn-outline>
                            </v-col>
                            <v-col cols="6">
                            <v-bottom-sheet v-model="historyToggle">
                                <template v-slot:activator="{ props }">
                                    <v-btn-outline block v-bind="props"  prepend-icon="far fa-history">History</v-btn-outline>
                                </template>

                                <v-card color="#EAEAEA" >
                                    <v-card-text>
                                        <v-form  @submit.prevent="assetStore.saveAssetHistory()">
                                            <v-row>
                                                <v-col cols="12">
                                                    <h3>Asset History</h3>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea v-model="history"  label="History"></v-textarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn type="submit" @click="historyToggle = false;" block  prepend-icon="fas fa-save">Save Changes</v-btn>
                                                </v-col>
                                                <v-col cols="12" v-if="latestAssetGrade">
                                                    <v-textarea :disabled="true" v-model="latestAssetGrade.recommendations" class="text-black" :label="'Most recent recommendation:'+moment(latestAssetGrade.graded_at).format('(DD/MM/YYYY)')" />
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-bottom-sheet>
                            </v-col>
                            <v-col cols="12">
                                <v-bottom-sheet v-model="replaceToggle">
                                    <template v-slot:activator="{ props }">
                                        <v-btn-outline block v-bind="props"  prepend-icon="far fa-tools">Repair/Replace</v-btn-outline>
                                    </template>

                                    <v-card color="#EAEAEA" >
                                        <v-card-text>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <h3>Repair/Replace Asset</h3>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <p>If you choose to repair/replace Asset, the system will..</p>
                                                        <ul>
                                                            <li>mark the asset’s grade as GREEN</li>
                                                            <li>note the change in the asset’s History</li>
                                                            <li>remove any outstanding Recommendations</li>
                                                        </ul>
                                                        <p>Note: if you need to edit the Asset number or change the QR code you can do this on the Edit Asset page.</p>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-btn @click="assetStore.saveRepairAsset(); replaceToggle = false" block  prepend-icon="fas fa-tools">Repair</v-btn>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-btn @click="assetStore.saveReplaceAsset(); replaceToggle = false" block  prepend-icon="fas fa-arrows-repeat">Replace</v-btn>
                                                    </v-col>
                                                </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-bottom-sheet>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
        </v-col>
        <v-col cols="12">
            <div class="px-3">
                <v-btn v-if="activeInspection && activeInspection.closed_at == null && checkIfActiveInspection()" block prepend-icon="fas fa-clipboard-question" :to="{ name: 'Asset-Survey', params: { asset_id: id, inspection_id: activeInspection.id}}">Start Inspection Survey</v-btn>
            </div>
        </v-col>
        <v-col cols="12" class="mt-3">
            <v-card>
                <v-card-text>

                    <v-row class="align-center">
                        <v-col class="me-auto">
                            <h3>Grading</h3>
                        </v-col>
                        <v-col cols="2" class="text-right">
                            <grading-options></grading-options>
                        </v-col>
                        <v-col no-gutters class="no-gutters" cols="12" v-for="(grading,key) in gradings " :key="'grade'+key">
                            <v-row class="d-flex w-100 align-start" no-gutters>
                                <template v-if="'date_from' in grading">
                                    <incomplete-inspection-grading v-if="activeInspection" :inspection="grading" :asset_id="id"></incomplete-inspection-grading>
                                </template>
                                <template v-else>

                                    <v-col cols="2">
                                        <v-radio-group style="max-width: 40px" inline v-model="grading.grade">
                                            <v-grade-green-radio width="2px" v-if="grading.grade == 'pass'"  value="pass"></v-grade-green-radio>
                                            <v-grade-red-radio  v-if="grading.grade == 'fail'" checked value="fail"></v-grade-red-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="3" class="mt-3">
                                    {{moment(grading.graded_at).format('MMM YYYY')}}
                                    </v-col>
                                    <v-col class="me-auto mt-3" >
                                        <strong>{{assetGradeText(grading)}}</strong>
                                    </v-col>
                                    <v-col cols="1" class="ml-auto text-right align-start" v-if="assetGradeText(grading) == 'Grade Change'">
                                        <v-bottom-sheet >
                                            <template v-slot:activator="{ props }">
                                                <v-btn-outline border="0" color="primaryRed" v-bind="props" class="bg-white" icon="fa-solid fa-trash"></v-btn-outline>
                                            </template>

                                            <v-card >
                                                <v-card-text>
                                                    <h3 class="mb-3">Are you sure you want to delete this grading?</h3>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-btn class="w-100" rounded="rounded" @click="assetGradesStore.deleteById( grading.id)" prepend-icon="fa-solid fa-trash">Yes</v-btn>
                                                        </v-col>
                                                    </v-row>

                                                </v-card-text>
                                                </v-card>
                                        </v-bottom-sheet>
                                    </v-col>
                                    <v-col cols="1" class="ml-auto text-right align-start mt-3" v-if="assetGradeText(grading) != 'Grade Change'">
                                        <v-btn-outline @click="viewSurvey(grading.id)" border="0" class="pa-0 align-start" :icon="'far ' + assetGradeIcon(grading)"></v-btn-outline>
                                    </v-col>
                                    <v-col cols="12"  style="line-height:16px;" v-if="grading.recommendations">
                                        <p><strong>Recommended remedial works:</strong></p>
                                        <p><small> <span v-html="formatRecommendations(grading.recommendations)"></span></small></p>
                                    </v-col>

                                </template>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">

            <v-bottom-sheet >
                <template v-slot:activator="{ props }">
                    <v-btn block color="primaryRed" v-bind="props" class="bg-white" prepend-icon="fa-solid fa-trash">Delete Asset</v-btn>
                </template>

                <v-card >
                    <v-card-text>
                        <h3 class="mb-3">Are you sure you want to delete this Asset?</h3>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="w-100" rounded="rounded" @click="assetsStore.deleteById( asset.id.value)" prepend-icon="fa-solid fa-trash">Yes</v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    </v-card>
            </v-bottom-sheet>
        </v-col>
    </v-row>
</template>
