import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';
import {filterState} from '../helpers/FilterWrapper';
import { useAppStore } from './app';
import { useAssetTypesStore } from './assetTypes';
import { useInspectionsStore } from './inspections';
import { answerStoreObject } from './answer';
import { v4 as uuidv4 } from 'uuid';
import { useAssetGradeStore } from './AssetGrade';
import { useAssetGradesStore } from './AssetGrades';
import { useUserStore } from './user';


const initialState = {
    assets: [],
    deep_rerender: uuidv4(),
}

export const useAssetsStore = defineStore('assets', {
    persistence: true,
    state: () => ( initialState),
    getters: {
        getAssetById: (state) => {
          return (assetId) => {
            return filterState(state.assets).deletedFilter().where('id', assetId).first()
          }
        },
        getAssetBySerialNumber: (state) => {
          return (serialNumber) => {
            return filterState(state.assets).deletedFilter().where('serial_number', serialNumber).first()
          }
        },
        getAssets: (state) => {
          return (fuzzyText, siteId) => {
            return filterState(state.assets).deletedFilter().siteFilter(siteId).assetFuzzyFilter(fuzzyText, ['serial_number', 'location', 'asset_type.name']).get();
          }
        },
        getAssetsBySiteIdAndInspectionAssetTypes: (state) => {
          return (siteId, assetTypes) => {
            return filterState(state.assets).deletedFilter().siteFilter(siteId).whereIn('asset_type_id', assetTypes).get();
          }
        },
        getInspectionAssetsPresentInAssetGrades: (state) => {
            return (inspection,assetTypeId) => {
                return filterState(state.assets).where('site_id', inspection.site_id).deletedFilter().byAssetType(assetTypeId).presentInAssetGrades(inspection.id).sortAsc('location').get();
            }
        },
        getInspectionAssetsNotPresentInAssetGrades: (state) => {
            return (inspection,assetTypeId) => {
                return filterState(state.assets).where('site_id', inspection.site_id).deletedFilter().byAssetType(assetTypeId).notPresentInAssetGrades(inspection.id).sortAsc('location').get();
            }
        },
        getAssetTypesBySiteId: (state) => {
            return (siteId) => {
                return filterState(state.assets).deletedFilter().where('site_id', siteId).get().map(asset => asset.asset_type );
            }
        },
        getAssetsBySiteIdAndAssetTypeIdNotPresentInAssetGrades: (state) => {
            return (siteId,assetTypeId) => {
                return filterState(state.assets).deletedFilter().where('site_id', siteId).byAssetType(assetTypeId).assetDoesNotHaveAssetGrades().get();
            }
        },
        getUniqueAssetTypesBySiteId: (state) => {
            return (siteId) => {
                const assetTypesStore = useAssetTypesStore();
                return [...new Set (filterState(state.assets).deletedFilter().where('site_id', siteId).get().map(asset => asset.asset_type.id))].map(id => assetTypesStore.getAssetTypesById(id));
            }
        },
    },
    actions: {
        async sync() {
          await fetchWrapper.sync(`/assets/index`,`/assets/update-or-create` ,{
              stored: this.assets.map(function (asset) { return {id:asset.id, updated_at: asset.updated_at}}),
          }, this.assets);
        },

        saveAsset(asset) {
            let assetIndex = this.$state.assets.findIndex((assetToFind) => assetToFind.id === asset.id)

            asset.updated_at = moment().format()

            if(this.assets[assetIndex] ) {
                this.assets[assetIndex] = asset
                const appStore =  useAppStore ();
                appStore.setSnackbar("Successfully updated Asset")
            } else {
                this.assets.push(asset)
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully created Asset")
            }
        },
        createAssetGradeForAllAssetTypeAssetsForInspection(inspectionObj,asset_type_id,graded_at) {
            const assetGradesStore = useAssetGradesStore()
            const inspection_id = inspectionObj.id.value;
            const inspectionStore = useInspectionsStore()
            const assetTypesStore = useAssetTypesStore()
            const assetType = assetTypesStore.getAssetTypesById(asset_type_id)
            const inspection =  inspectionStore.getInspectionById(inspection_id)

            this.getInspectionAssetsNotPresentInAssetGrades(inspection, asset_type_id).forEach(asset => {
                const assetGradeStore = useAssetGradeStore()
                const user = useUserStore()

                assetGradeStore.$state.asset_id = asset.id;
                assetGradeStore.$state.inspection_id = inspection_id;
                assetGradeStore.$state.grade = 'pass';
                assetGradeStore.$state.graded_at = graded_at;
                assetGradeStore.$state.auto_complete_survey = true;
                assetGradeStore.$state.created_by_id  = user.$state.user.id
                assetGradeStore.$state.updated_at = moment().format()

                assetType.questions.forEach(question => {

                    const storeId = uuidv4()+'-answer';
                    const answerStore = defineStore (storeId, answerStoreObject)();
                    const question_answer_type = question.question_answer_types.find(question_answer_type => question_answer_type.answer_type.component_name == 'answer-type-radio-buttons');
                    if(question_answer_type)
                    {
                        answerStore.$state.value = JSON.stringify(JSON.parse(question_answer_type.answer_type.props).options[0]);
                        answerStore.$state.question_answer_type = question_answer_type;
                        answerStore.$state.question_answer_type_id = question_answer_type.id;
                        answerStore.$state.question_id = question_answer_type.question_id
                        answerStore.$state.answer_type_id = question_answer_type.answer_type_id

                        answerStore.$state.asset_grade_id = assetGradeStore.$state.id;
                        assetGradeStore.$state.answers.push(cloneDeep(answerStore.$state))
                    }

                });

                if(assetGradeStore.$state.answers.length)
                {
                    assetGradesStore.$state.assetGrades.push(cloneDeep(assetGradeStore.$state))

                    const appStore =  useAppStore();
                    appStore.setSnackbar("Successfully created Asset Grade")
                }


                assetGradeStore.$reset();
                assetGradeStore.$state.id = uuidv4();
                this.$state.deep_rerender = uuidv4();
            });
        },
        deleteById(id) {
            const index = this.$state.assets.findIndex((asset) => asset.id == id)
            this.$state.assets[index].updated_at = moment().format()
            this.$state.assets[index].deleted_at = moment().format()
            this.$state.deep_rerender = uuidv4()
            const appStore = useAppStore()
            const vm = this
            this.router.push({name: 'Inspections'})
            appStore.setSnackbar("Successfully deleted Asset",function(){

                vm.$state.assets[index].updated_at = moment().format()
                vm.$state.assets[index].deleted_at = null
                vm.$state.deep_rerender = uuidv4()
                vm.router.push({name: 'Inspections'})
            })

        }
    }
})
