
import { defineStore } from 'pinia'

import { cloneDeep, isEqual } from "lodash";
import { useInspectionsStore } from './inspections';
import { useSitesStore } from './sites';
import { useUserStore } from './user';

import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import moment from 'moment';
import { uniqueUsername} from '@/helpers/CustomValidators'
import { helpers } from '@vuelidate/validators'
import { email, required, sameAs } from '@vuelidate/validators'


const initialState = (uuidv4) => {return {
    'id': uuidv4,
    'site' : null,
    'site_id' : null,
    'date_from_formatted' : null,
    'date_from' : null,
    'date_to_formatted' : null,
    'date_to' : null,
    'title' : null,
    'asset_types' : null,
    'inspectors' : null,
    'instruction' : null,
    'scope' : null,
    'status': null,
    'closed_at' : null,
    'updated_at' : null,
    'deleted_at' : null,
}}

export const rules = (state,route) => { return {
    site : {required},
    site_id : {required},
    date_from_formatted : {},
    date_from : {required},
    date_to_formatted : {},
    date_to : {required},
    title : {required},
    asset_types : {required},
    inspectors : {required},
    instruction : {},
    scope : {required},
    status: {required},
    closed_at : {},
}}

export const inspectionStoreObject = {
    //As part of my persistence plugin will allow a state to be initialized after a state has been synced from storage
    initByRoute: (route) => {
        const inspectionsStore =  useInspectionsStore();
        return route.params.inspection_id ? inspectionsStore.getInspectionById(route.params.inspection_id) : cloneDeep(initialState(uuidv4()))
    },
    state: () => ( cloneDeep(initialState(uuidv4()))),
    getters: {
        inspectors_names: (state) => state.inspectors?.length ? (
            state.inspectors.map((inspector) => inspector.forename + ' ' + inspector.surname ).slice(0, -1).join(', ')
            + (state.inspectors.length > 1 ? ' and ' : '' )
            + state.inspectors.map((inspector) => inspector.forename + ' ' + inspector.surname ).slice(-1)
        ) : null,
        date_range: (state) => state.date_from_formatted == state.date_to_formatted ? state.date_from_formatted : state.date_from_formatted+ ' - ' + state.date_to_formatted,
        site: (state) => {
            const siteStore = useSitesStore();
            return siteStore.getSiteById(state.site_id)
        },
        status: (state) => state.closed_at == null ? 'Open' : 'Closed/Locked'
    },
    actions: {
        async saveInspection(v$) {
            const siteStore = useSitesStore();


            const inspectionsStore =  useInspectionsStore();
            const isFormCorrect = await v$.$validate()
            if(isFormCorrect){
                this.formatDates();
                this.$state.site = siteStore.getSiteById(this.$state.site_id)
                inspectionsStore.saveInspection(cloneDeep(this.$state), this.router)
                this.router.push({name: 'Inspections'})
            }
        },
        async closedAt() {
            const inspectionsStore =  useInspectionsStore();
            inspectionsStore.closeById(this.$state.id)
        },

        formatDates() {
            this.$state.date_from_formatted = moment(this.date_from).format('DD/MM/YYYY');
            this.$state.date_to_formatted = moment(this.date_to).format('DD/MM/YYYY');
        }
    },
}

export const useInspectionStore = defineStore('inspection', inspectionStoreObject )

