
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import {filterState} from '../helpers/FilterWrapper';
import { useAppStore } from './app';
import { v4 as uuidv4 } from 'uuid';


const initialState = {
    users: [],
    deep_rerender: uuidv4(),
}

export const useUsersStore = defineStore('users', {
    persistence: true,
    state: () => (initialState),
    getters: {
        getUserById: (state) => {
          return (userId) => {
            return state.users.find((user) => user.id === userId)
          }
        },
        getUsers: (state) => {
          return (fuzzyText) => {
            return filterState(state.users).deletedFilter().fuzzyFilter(fuzzyText).get();
          }
        },
    },
    actions: {
        async sync() {
          await fetchWrapper.sync(`/users/index`,`/users/update-or-create` ,{
              stored: this.users.map(function (user) { return {id:user.id, updated_at: user.updated_at}}),
          }, this.users);
        },

        save(user){
            let userIndex = this.$state.users.findIndex((userToFind) => userToFind.id === user.id)
            user.updated_at = moment().format()
            this.$state.deep_rerender = uuidv4()
            if(this.users[userIndex] ) {
                this.users[userIndex] = user
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully saved changes")
            } else {
                this.users.push(user)
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully created User")
            }
        }
    }
})
