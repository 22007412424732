
import { defineStore } from 'pinia'

import { cloneDeep, isEqual } from "lodash";
import { useSitesStore } from './sites';
import { useUserStore } from './user';

import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import { useInspectionsStore } from './inspections';

const initialState =(uuidv4) => { return {
    'id': uuidv4,
    'name': '',
    'client_name': '',
    'address': '',
    'address_line_1': '',
    'address_line_2': '',
    'town': '',
    'post_code': '',
    'notes': '',
    'events_log': '',
    'assets': [],
    'created_at': null,
    'updated_at': null,
    'delete_at': null,
}}

export const useSiteStore = defineStore('site', {
    //As part of my persistence plugin will allow a state to be initialized after a state has been synced from storage
    initByRoute: (route) => {
        const sitesStore =  useSitesStore();
        return route.params.site_id ? sitesStore.getSiteById(route.params.site_id) : cloneDeep(initialState(uuidv4()))
    },
    state: () => ( cloneDeep(initialState(uuidv4()))),
    getters: {
        inspections: (state) => {
            const inspectionsStore = useInspectionsStore()
            return inspectionsStore.getInspectionsBySiteId(state.id)
        }
    },
    actions: {
        saveSite() {
            const sitesStore =  useSitesStore();
            sitesStore.saveSite(cloneDeep(this.$state), this.router)
            this.router.push({name: 'Sites'})
        },
    },
})

