
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';

import { cloneDeep, isEqual } from "lodash";
import { useAssetsStore } from './assets';
import { useUserStore } from './user';

import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';

import { helpers } from '@vuelidate/validators'
import { email, required, sameAs } from '@vuelidate/validators'
import { useSurveyAnswersStore } from './surveyAnswers';

const initialState = (uuidv4) => { return {
        'id': uuidv4,
        question_answer_type_id : null,
        question_answer_type : {
            id: null,
            question_id: null,
            answer_type_id: null,
        },
        question_id: null,
        answer_type_id: null,
        value: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
    }

}

export const answerStoreObject = {
    state: () => ( cloneDeep(initialState(uuidv4()))),
    actions: {
        async saveAnswer(question_answer_type) {
            const surveyAnswers =  useSurveyAnswersStore();

            this.$state.question_answer_type = question_answer_type
            this.$state.question_answer_type_id = question_answer_type.id
            this.$state.question_id = question_answer_type.question_id
            this.$state.answer_type_id = question_answer_type.answer_type_id

            surveyAnswers.saveAnswer(cloneDeep(this.$state), this.router)
        },
    },
}

