/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */
import "vuetify/styles";


// Styles
import '@mdi/font/css/materialdesignicons.css'

// Composables
import { createVuetify } from 'vuetify'
import { VBtn, VIcon, VRadio } from 'vuetify/components'
import { aliases, fa } from 'vuetify/iconsets/fa'

const myCustomDarkTheme = {
    dark: true,
    colors: {
      background: '#252523',
      surface: '#ffffff',
      'on-surface': '#252523',
      primary: '#01729A',
      primaryRed: '#C6002A',
      gradeGreenBackground: 'rgba(76, 200, 0, 0.2)',
      gradeGreenBorder: 'rgba(55, 143, 0, 0.2)',
      gradeAmberBackground: 'rgba(255, 122, 0, 0.2)',
      gradeAmberBorder: 'rgba(142, 68, 0, 0.2)',
      gradeRedBackground: 'rgba(220, 0, 0, 0.2)',
      gradeRedBorder: 'rgba(133, 0, 0, 0.2)',
      fontDark: '#252523',
      dark: '#252523',
      snackbar: "#EAEAEA"

    },
  }


// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    components: {
    },
    aliases: {
        VBtnSecondary: VBtn,
        VBtnOutline: VBtn,
        VIconPrimaryRed: VIcon,
        VGradeGreenRadio: VRadio,
        VGradeAmberRadio: VRadio,
        VGradeRedRadio: VRadio,
        VGradeWhiteRadio: VRadio,
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    theme: {
        defaultTheme: 'dark',
        themes: {
            dark: myCustomDarkTheme,
        },
    },
    defaults: {
        VBtn: {
            color: 'primary',
            variant: 'flat',

        },
        VGradeGreenRadio: {
            variant: 'elevated',
            falseIcon: 'fa-solid fa-square-full grade-green-unselected',
            trueIcon: 'fa-solid  fa-square-full grade-green-selected'
        },
        VGradeAmberRadio: {
            variant: 'elevated',
            falseIcon: 'fa-solid fa-square-full grade-amber-unselected',
            trueIcon: 'fa-solid  fa-square-full grade-amber-selected'
        },
        VGradeRedRadio: {
            variant: 'elevated',
            falseIcon: 'fa-solid fa-square-full grade-red-unselected',
            trueIcon: 'fa-solid  fa-square-full grade-red-selected'
        },
        VGradeWhiteRadio: {
            variant: 'elevated',
            falseIcon: 'fa-solid fa-square-full grade-white-unselected',
            trueIcon: 'fa-solid  fa-square-full grade-white-selected'
        },
        VOverlay: {
            scrim:'black'
        },
        VBottomSheet: {
            scrim:'black'
        },
        VDatePicker: {
            theme: 'light'
        },
        VBtnSecondary: {
            color: 'secondary',
            variant: 'flat',
        },
        VBtnOutline: {
            color: 'primary',
            variant: 'outlined',
            border: '10px'
        },
        VTextField: {
            bgColor: 'white',
            details: {
                paddingLeft: 0,
            },
        },
        VTextarea: {
            bgColor: 'white'
        },
        VCard: {

        },
        VIconPrimaryRed: {
            color: 'primaryRed',
        },
    }
})
