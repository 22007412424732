import { useUserStore } from '@/stores/user';

const BASE_URL = import.meta.env.VITE_API_BASE_URL ;

export const fetchWrapper = {
    sync: sync(false),
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
};

//if a state is passed in then it can handle updating and pushing new items to it
function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };

        requestOptions.headers['Accept'] = 'application/json';

        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }

        return fetch(BASE_URL + url, requestOptions).then((response) => handleResponse(response));
    }
}
//if a state is passed in then it can handle updating and pushing new items to it
const pendingRequests = {};

function sync(preventSync) {
    return (indexUrl, updateOrCreateUrl, body, state) => {
        if (pendingRequests[indexUrl]) {
            console.warn(`Request to ${indexUrl} is already pending.`);
            return; // Exit if a request to the same URL is still pending
        }

        pendingRequests[indexUrl] = true;

        try {
            fetchWrapper.post(indexUrl, body, state).then(function(data) {
                // `data` is the parsed version of the JSON returned from the above endpoint.
                let responseData = data;
                if ("required_ids" in responseData) {
                    for (const id of responseData.required_ids) {
                        let elementIndex = state.findIndex(item => item.id == id);
                        let element = state[elementIndex];

                        fetchWrapper.post(updateOrCreateUrl, element).then(function(response) {
                            state[elementIndex] = response.data;
                        });
                    }

                    if (preventSync) {
                        console.error("FAILED SYNC");
                    } else {
                        sync(true)(indexUrl, updateOrCreateUrl, body, state);
                    }
                } else {
                    responseData.new_data.forEach(element => {
                        state.push(element);
                    });

                    responseData.updated_data.forEach(element => {
                        let assetIndex = state.findIndex(assetToFind => assetToFind.id === element.id);
                        if (state[assetIndex]) {
                            state[assetIndex] = element;
                        } else {
                            state.push(element);
                        }
                    });

                    responseData.deleted_data.forEach(id => {
                        let assetIndex = state.findIndex(assetToFind => assetToFind.id === id);
                        if (state[assetIndex]) {
                            state.splice(assetIndex, 1);
                        }
                    });
                }
                state.sync_loaded = false;
            });

        } catch (error) {
            console.error(`Error in request to ${indexUrl}:`, error);
        } finally {
            delete pendingRequests[indexUrl];
        }
    };
}


// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { user } = useUserStore();
    const isLoggedIn = !!user?.access_token;

    const isApiUrl = url.startsWith(import.meta.env.VITE_API_BASE_URL);
    if (isLoggedIn) {
        return {
            "Authorization": `Bearer ${user.access_token}`,
        };
    } else {
        return {
        }
    }
}

function handleResponse(response) {
    return response.json().then(data => {
        if (!response.ok) {
            const { user, logout } = useUserStore();
            if ([401, 403].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
