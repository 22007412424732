import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '../stores/user';
import { useAppStore } from '@/stores/app';
import Guest from '@/layouts/default/Guest.vue';
import Default from '@/layouts/default/Default.vue';
import Survey from '@/layouts/default/Survey.vue';
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';
import AccountView from '@/views/AccountView.vue';
import InspectionsView from '@/views/InspectionsView.vue';
import InspectionCreateView from '@/views/InspectionCreateView.vue';
import InspectionView from '@/views/InspectionView.vue';
import InspectionAssets from '@/views/InspectionAssets.vue';
import UsersView from '@/views/UsersView.vue';
import UserCreateView from '@/views/UserCreateView.vue';
import SitesView from '@/views/SitesView.vue';
import SiteCreateView from '@/views/SiteCreateView.vue';
import SiteView from '@/views/SiteView.vue';
import AssetCreateView from '@/views/AssetCreateView.vue';
import AssetOverviewView from '@/views/AssetOverviewView.vue';
import SurveyView from '@/views/SurveyView.vue';
import AnswerView from '@/views/AnswerView.vue';
import HubWelcomeView from '@/views/Hub/HubWelcomeView.vue';
import HubInspectionView from '@/views/Hub/HubInspectionView.vue';

const ADMIN_USER_ROLE_ID = 1;

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Guest,
            children: [
              {
                path: '',
                name: 'Home',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: LoginView,
              },
            ],
            beforeEnter: async (to, from, next) => {
                const userStore = useUserStore()
                if (userStore.isLoggedIn()) {
                    return next("/sites");
                }

                next();
            },

        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: DashboardView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/test-sentry',
            name: 'testSentry',
            component: () => import('../views/TestSentry.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/my-account',
            name: 'my-account',
            component: Default,
            children: [
              {
                path: '',
                name: 'My-Account',
                // route level code-splitting
                component: AccountView,
              },
            ],
            meta: {
                requiresAuth: true,
                title: "My Account",
            },
        },
        {
            path: '/inspections/:site_id?',
            name: 'inspections',
            component: Default,
            children: [
              {
                path: '',
                name: 'Inspections',
                // route level code-splitting
                component: InspectionsView,
              },
            ],
            meta: {
                requiresAuth: true,
                title: "Inspections",
            },
        },
        {
            path: '/inspections/create/:site_id?',
            name: 'inspection-create',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Inspection-Create',
                  // route level code-splitting
                    component: InspectionCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "New Inspection",
                backRouteName: "Inspections",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/inspections/edit/:inspection_id',
            name: 'inspection-edit',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Inspection-Edit',
                  // route level code-splitting
                    component: InspectionCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Edit Inspection",
                backRouteName: "Inspections",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/inspections/view/:inspection_id',
            name: 'inspection',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Inspection',
                  // route level code-splitting
                    component: InspectionView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Inspection",
                backRouteName: "Inspections",
            },
        },
        {
            path: '/inspections/view/:inspection_id/assets/:asset_type_id',
            name: 'inspection-assets',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Inspection-Assets',
                  // route level code-splitting
                    component: InspectionAssets,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "View Assets",
                backRouteName: "Inspections",
            },
        },
        {
            path: '/users',
            name: 'users',
            component: Default,
            children: [
              {
                path: '',
                name: 'Users',
                // route level code-splitting
                component: UsersView,
              },
            ],
            meta: {
                requiresAuth: true,
                title: "Users",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/user/create',
            name: 'user-create',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'User-Create',
                  // route level code-splitting
                    component: UserCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "New User",
                backRouteName: "Users",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/user/:user_id',
            name: 'user-edit',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'User-Edit',
                  // route level code-splitting
                    component: UserCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Edit User",
                backRouteName: "Users",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/sites',
            name: 'sites',
            component: Default,
            children: [
              {
                path: '',
                name: 'Sites',
                // route level code-splitting
                component: SitesView,
              },
            ],
            meta: {
                requiresAuth: true,
                title: "Sites",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/site/create',
            name: 'site-create',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Site-Create',
                  // route level code-splitting
                    component: SiteCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "New Site",
                backRouteName: "Sites",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/site/edit/:site_id',
            name: 'site-edit',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Site-Edit',
                  // route level code-splitting
                    component: SiteCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Edit Site",
                backRouteName: "Sites",
                access_level: [ADMIN_USER_ROLE_ID]
            },
        },
        {
            path: '/site/:site_id',
            name: 'site',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Site',
                  // route level code-splitting
                    component: SiteView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Site",
                backRouteName: "Sites",
            },
        },
        {
            path: '/assets/create/:site_id?',
            name: 'asset-create',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Asset-Create',
                  // route level code-splitting
                    component: AssetCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Add Asset",
                backRouteName: "Inspections",
            },
        },
        {
            path: '/assets/edit/:asset_id?',
            name: 'asset-edit',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Asset-Edit',
                  // route level code-splitting
                    component: AssetCreateView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Edit Asset",
                backRouteName: "Inspections",
            },
        },
        {
            path: '/assets/overview/:asset_id/:inspection_id?',
            name: 'asset-overview',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Asset-Overview',
                  // route level code-splitting
                    component: AssetOverviewView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Asset Overview",
                backRouteName: "Sites",
            },
        },
        {
            path: '/assets/survey/:asset_id/:inspection_id?',
            name: 'asset-survey',
            component: Survey,
            children: [
                {
                    path: '',
                    name: 'Asset-Survey',
                  // route level code-splitting
                    component: SurveyView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Asset Survey",
            },
        },
        {
            path: '/assets/survey/:asset_id/answers/:inspection_id?',
            name: 'asset-survey-answers',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Asset-Survey-Answers',
                    component: AnswerView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Survey Summary",
                backRouteName: "Inspections",
            },
        },
        {
            path: '/assets/survey/:asset_id/answers/:inspection_id/asset-grade/:asset_grade_id',
            name: 'asset-grade-survey-answers',
            component: Default,
            children: [
                {
                    path: '',
                    name: 'Asset-Grade-Survey-Answers',
                    component: AnswerView,
                },
            ],
            meta: {
                requiresAuth: true,
                title: "Survey Summary",
                backRouteName: "Inspections",
            },
        },
    ]
})

router.beforeEach(async (to) => {
    // ✅ This will work because the router starts its navigation after
    // the router is installed and pinia will be installed too
    const appStore = useAppStore();
    const userStore = useUserStore();
    await userStore.initUser()

    if (to.meta.requiresAuth && !userStore.isLoggedIn()) return '/'

    if(to.meta.access_level && !to.meta.access_level.includes(userStore.user_role_id)) {
        userStore.logout();
        return '/';
    }

})

export default router
