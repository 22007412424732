

import localForage from "localforage";
import { cloneDeep, isEqual } from "lodash";
import { useRoute, useRouter } from 'vue-router'
import { watch } from 'vue';


export async function syncPlugin({store,options}) {


    // if('sync' in options.actions){
    //     store.$onAction((state) => {
    //         console.log("state")
    //         console.log(state)
    //         options.actions.sync()

    //     })
    // }

}
