
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';
import {filterState} from '../helpers/FilterWrapper';
import { useAppStore } from './app';

const initialState = {
    sites: [],
}

export const useSitesStore = defineStore('sites', {
    persistence: true,
    state: () => ( initialState),
    getters: {
        getAssetsById: (state) => {
          return (siteId) => {
            return {assets: state.sites.find((site) => site.id === siteId)?.assets ?? []}
          }
        },
        getSiteById: (state) => {
          return (siteId) => {
            return state.sites.find((site) => site.id === siteId)
          }
        },
        getSites: (state) => {
          return (fuzzyText) => {
            return filterState(state.sites).deletedFilter().sortAsc('name').fuzzyFilter(fuzzyText).get();
          }
        },
    },
    actions: {
        async sync() {
          await fetchWrapper.sync(`/site/index`,`/site/update-or-create` ,{
              stored: this.sites.map(function (site) { return {id:site.id, updated_at: site.updated_at}}),
          }, this.sites);
        },

        saveSite(site) {
            let siteIndex = this.$state.sites.findIndex((siteToFind) => siteToFind.id === site.id)

            site.updated_at = moment().format()
            site.address = site.address_line_1 + ", " + (site.address_line_2 ? site.address_line_2 + ", " : "") + site.town + ", " + site.post_code;

            if(this.sites[siteIndex] ) {
                this.sites[siteIndex] = site
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully updated Site")
            } else {
                this.sites.push(site)
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully created Site")
            }
        },
        deleteSite(id) {

            const siteId = this.$state.sites.findIndex((site) =>  site.id == id);
            this.$state.sites[siteId].deleted_at = moment().format();
            this.$state.sites[siteId].updated_at = moment().format()
            this.router.push({name: 'Sites'})
        },

        undoDelete(id) {

            const siteId = this.$state.sites.findIndex((site) =>  site.id == id);
            this.$state.sites[siteId].deleted_at = null;
            this.$state.sites[siteId].updated_at = moment().format()

        }
    }
})
