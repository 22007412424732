<template>
    <v-col no-gutters class="no-gutters" cols="12" v-if="inspection">
        <v-row class="d-flex w-100 align-start" no-gutters>
            <v-col cols="2">
                <v-radio-group style="max-width: 40px" inline :model-value="'white'">
                    <v-grade-white-radio  value="white"></v-grade-white-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="3" class="mt-3">
            {{moment(inspection.date_from).format('MMM YYYY')}}
            </v-col>
            <v-col class="me-auto mt-3" >
            <strong>Not Inspected - {{inspection.title}}</strong>
            </v-col>
            <v-col cols="1" class="ml-auto text-right align-start mt-3">
                <v-btn-outline :to="{name: 'Asset-Survey', params: {'asset_id': asset_id, 'inspection_id': inspection.id} }" border="0" class="pa-0 align-start" :icon="inspection.closed_at ?  'fas fa-lock' : 'far fa-arrow-right'"></v-btn-outline>
            </v-col>
        </v-row>
    </v-col>
</template>

<script setup>
import { defineProps } from 'vue'
import { storeToRefs } from 'pinia';
import { useInspectionsStore } from '../stores/inspections';
import moment from 'moment'

const props = defineProps (['inspection', 'asset_id'])
const inspection = props.inspection
const asset_id = props.asset_id
</script>
