<template>
  <v-app class="survey-layout">
    <default-header>
    </default-header>
    <default-view  />
  </v-app>
</template>

<script>
import { storeToRefs } from 'pinia';
import DefaultHeader from './Header.vue'
import DefaultBar from './AppBar.vue'
import DefaultView from './View.vue'
import { useAppStore } from '../../stores/app';
export default {
    components: {
        DefaultHeader,
        DefaultBar,
        DefaultView
    },
    mounted () {


    },

    setup () {

        const appStore = useAppStore();
        const app = storeToRefs(appStore)


        return {
            appStore,
            ...app
        }

    }
}
</script>
