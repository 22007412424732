<template>
    <v-card :title="'Event Log'">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-textarea label="Events Log" v-model="events_log"></v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-btn-outline @click="siteStore.saveSite()" class="w-100" prepend-icon="fa-solid fa-save">Save Changes</v-btn-outline>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { defineProps } from 'vue'
import { storeToRefs } from 'pinia';
import { useSiteStore } from '@/stores/site.js';
import { useUserStore } from '@/stores/user';

const siteStore = useSiteStore()
const userStore = useUserStore()
const {events_log} = storeToRefs(siteStore)
const {isAdmin} =  userStore

</script>
