<script setup>
import { storeToRefs } from 'pinia';
import { ref,computed } from 'vue'
import { useRoute } from 'vue-router';
import { useAssetTypesStore } from '../stores/assetTypes';
import { useInspectionsStore } from '../stores/inspections';
import { useInspectionStore } from '../stores/inspection';
import moment from 'moment';

import { useAssetsStore } from '../stores/assets';
import DatePicker from '@/components/DatePicker.vue'


const route = useRoute();
const inspectionsStore = useInspectionsStore();
const assetTypesStore = useAssetTypesStore();
const assetsStore = useAssetsStore();
const inspectionStore = useInspectionStore()
const inspection = storeToRefs(inspectionStore)
const assetType = computed( () => {return assetTypesStore.getAssetTypesById(route?.params?.asset_type_id)})
const getInspectionAssetsNotPresentInAssetGrades = computed( () => { return assetsStore.getInspectionAssetsNotPresentInAssetGrades(inspectionStore.$state, route?.params?.asset_type_id)})
const getInspectionAssetsPresentInAssetGrades = computed( () => { return assetsStore.getInspectionAssetsPresentInAssetGrades(inspectionStore.$state, route?.params?.asset_type_id)})
const assetTypeSelection = ref();
const graded_at = ref(moment() .format());

</script>

<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <h3>All <span class="text-primaryRed">{{assetType?.plural_name ?? assetType?.name}}</span>...</h3>
                </v-col>
                <v-col cols="12"  v-if="getInspectionAssetsNotPresentInAssetGrades?.length" class="d-flex">
                    <div>
                    <h3>NOT Tested in this Inspection</h3>
                    </div>
                    <div class="ms-auto">
                        <v-bottom-sheet v-if="assetType.is_complex != 1 ">
                            <template v-slot:activator="{ props }">
                                <v-btn-outline v-bind="props" border="0" icon="far fa-ellipsis"></v-btn-outline>
                            </template>

                            <v-card >
                                <v-card-text>
                                    <h3>Options...</h3>
                                    <p>You can auto-complete surveys on the following asset types in your scope... (this will answer all questions as ‘Yes’/’Pass’ and grade the asset Green)</p>
                                    <v-form @submit.prevent="assetsStore.createAssetGradeForAllAssetTypeAssetsForInspection(inspection, assetTypeSelection,graded_at)">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-checkbox v-model="assetTypeSelection" hide-details :value="assetType.id"  :label="assetType.name + ' (' + assetsStore.getInspectionAssetsNotPresentInAssetGrades(inspectionStore.$state, assetType.id)?.length + ')'"></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" v-if="assetTypeSelection">
                                                <date-picker append-inner-icon="far fa-calendar" label="Date" v-model="graded_at"></date-picker>
                                            </v-col>
                                            <v-col :key="assetType.id" cols="12">
                                                <v-btn :disabled="!assetTypeSelection" type="submit"  block prepend-icon="fas fa-circle-check">Auto-complete Survey(s)</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>

                                </v-card-text>
                            </v-card>
                        </v-bottom-sheet>
                    </div>
                </v-col>
                <v-col cols="12"  v-for="(asset,index) in getInspectionAssetsNotPresentInAssetGrades" :key="assetsStore.$state.deep_rerender + asset?.id + index">
                        <v-card @click="value = false" color="primary" class="rounded-pill" :to="{name: 'Asset-Overview', params: {asset_id: asset.id, inspection_id: route?.params?.inspection_id}}">
                            <v-card-text>
                                <v-row class="align-center">
                                    <v-col cols="3" class="flex-shrink-1"><strong>{{asset?.serial_number}}</strong></v-col>
                                    <v-col cols="7"><div>{{asset?.asset_type?.name}}</div><div>{{asset?.location}}</div></v-col>
                                    <v-col cols="2" class="flex-shrink-1"><v-icon icon="far fa-arrow-right"/></v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                </v-col>
                <v-col cols="12"  v-if="getInspectionAssetsPresentInAssetGrades?.length">
                    <h3>Tested in this Inspection</h3>
                </v-col>
                <v-col  cols="12"  v-for="(asset,index) in getInspectionAssetsPresentInAssetGrades" :key="assetsStore.$state.deep_rerender + asset?.id + index">
                        <v-card variant="outlined" @click="value = false" color="primary" class="rounded-pill" :to="{name: 'Asset-Overview', params: {asset_id: asset.id, inspection_id: route?.params?.inspection_id}}">
                            <v-card-text>
                                <v-row class="align-center">
                                    <v-col cols="3" class="flex-shrink-1"><strong>{{asset?.serial_number}}</strong></v-col>
                                    <v-col cols="7"><div>{{asset?.asset_type?.name}}</div><div>{{asset?.location}}</div></v-col>
                                    <v-col cols="2" class="flex-shrink-1"><v-icon icon="far fa-arrow-right"/></v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
