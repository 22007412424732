import QrScanner from 'qr-scanner/qr-scanner.min.js'
import { ref } from 'vue'

export const qrCode = {
    init: init()
}

function init () {
    return (element,refMethod) => {
        const scanner = new QrScanner(
        element,
        result => refMethod(result),
        {
            highlightScanRegion:true,
        },
        )
        return scanner
    }
}
