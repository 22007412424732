<template>
    <v-snackbar v-model="snackbar.show" block variant="elevated" min-width="100vw" max-width="100vw" style="margin-bottom: 120px" color="snackbar"   elevation="24">
        {{ snackbar.message }}

        <template v-slot:actions>
            <v-btn
            color="primaryRed"
            variant="text"
            v-if="snackbar.undoMethod"
            @click="appStore.undo"
            >
            Undo
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import { useRoute , useRouter} from "vue-router";
import {ref, watch} from 'vue'
import { useAppStore } from "@/stores/app";
import { storeToRefs } from "pinia";

const appStore = useAppStore();
const {snackbar} = storeToRefs(appStore);
</script>
