<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useUserStore } from '@/stores/user';


export default {
    setup() {
        const authStore = useUserStore();
        const { user: authUser } = storeToRefs(authStore);

        // expose the ref to the template
        return {
            authUser,
            authStore
        }
    },


}
</script>

<template>
  <div class="about">
        <h1 v-if="authUser">Hi {{authUser?.forename }} you are logged in</h1>
        <button @click="authStore.getMe">Get Me</button>
        <button @click="authStore.logout">Logout</button>
  </div>
</template>
