<template>
    <v-card v-if="question" >
        <v-card-text>
            <h3 class="pb-2">{{question.name}}:</h3>
        <template v-for="(index) in 10"  :key="'container'+index" >
            <v-row>
                <KeepAlive>
                    <component :index="index" v-if="questionAnswerTypes[index-1]?.answer_type" :is="questionAnswerTypes[index-1]?.answer_type.component_name" v-bind="JSON.parse(questionAnswerTypes[index-1]?.answer_type.props)" :key="question.id+questionAnswerTypes[index-1]?.answer_type.component_name" :question="question" :answerTypeId="questionAnswerTypes[index-1]?.answer_type.id"/>
                </KeepAlive>
            </v-row>
        </template>



        </v-card-text>

    </v-card>
</template>

<script >
import { storeToRefs } from 'pinia';
import { defineProps, watch, ref } from 'vue'
import { useAssetTypesStore } from '../stores/assetTypes.js';
import { useAssetStore, rules } from '../stores/asset.js';


export default {
    props: ['questionIndex'],
    emits: ['update:modelValue'],

    computed: {
        question () {
            if(this.assetTypesStore.getQuestionsByAssetTypeId(this.asset.asset_type_id)){

                return this.assetTypesStore.getQuestionsByAssetTypeId(this.asset.asset_type_id)[this.questionIndex];
            }

        },
        questionAnswerTypes() {
            return this.question?.question_answer_types?.sort((a,b) => {return a.order-b.order}) ?? []
        }

    },


    data() {

        const assetTypesStore = useAssetTypesStore()
        const assetStore = useAssetStore()
        const asset = storeToRefs(assetStore);
        return {
            assetTypesStore,
            asset
        }
    },

}


//assetTypesStore.getQuestionByAssetTypeId(asset.asset_type_id)[props.ind]
</script>
