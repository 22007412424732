
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { clone, cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';
import {filterState} from '../helpers/FilterWrapper';
import { useAssetGradesStore } from './AssetGrades';
import { v4 as uuidv4 } from 'uuid';


const initialState = {
    answers: [],
}


export const useSurveyAnswersStore = defineStore('surveyAnswers', {
    persistence: false,
    state: () => ( cloneDeep(initialState)),
    getters: {
        getAnswers: (state) => {
          return () => {
            return state.answers;
          }
        },
        getAnswerByQuestionAnswerTypeId: (state) => {
          return (question_answer_type_id) => {
                return state.answers.find((answer) => {
                    return (answer.question_answer_type_id == question_answer_type_id)
                })
          }
        },
        getAnswerByQuestionIdAndAnswerId: (state) => {
          return (questionId, answerTypeId) => {
                return state.answers.find((answer) => {
                    return (answer.answer_type_id == answerTypeId) && (answer.question_id  == questionId)
                })
          }
        },
    },
    actions: {
        resetState() {
            this.$state = cloneDeep(initialState)
        },
        saveAnswer(answer) {
            let answerIndex = this.$state.answers.findIndex((answerToFind) => answerToFind.id === answer.id)

            answer.updated_at = moment().format()

            if(this.answers[answerIndex] ) {
                this.answers[answerIndex] = answer
            } else {
                this.answers.push(answer)
            }
        },
        initViaPreviousAssetGrade(asset) {
            const assetGradesStore = useAssetGradesStore()
            const assetGradeAnswers = cloneDeep(assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).answers)
            assetGradeAnswers.forEach(answer => {
                answer.id = uuidv4()
                answer.value = JSON.parse(answer.value)
            });

            this.$state.answers = assetGradeAnswers
            this.$state.grade = assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).grade
            this.$state.grade_at = assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).grade_at

        },
        initViaAssetGradeId(asset_grade_id) {
            const assetGradesStore = useAssetGradesStore()
            const assetGradeAnswers = cloneDeep(assetGradesStore.getAssetGradeById(asset_grade_id).answers)
            this.$state.answers = assetGradeAnswers


        }
    }
})
