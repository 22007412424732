<template>
    <v-card :title="'Statistics and Reports'">
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <form :action="'/inspections/report/' + id"  @submit.prevent="onSubmit">
                        <v-btn :loading="submitting"  color="primary" download class="w-100" prepend-icon="fas fa-download" type="submit">
                            Download Report
                        </v-btn >
                    </form>
                </v-col>
                <v-col cols="6">
                    <v-btn class="w-100" color="primary" prepend-icon="fas fa-lock-keyhole"  @click="inspectionStore.closedAt()">
                        Close/Lock<br>
                        Inspection
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>

import {storeToRefs} from 'pinia';
import {useSiteStore} from '@/stores/site.js';
import {useUserStore} from '@/stores/user';
import {useInspectionStore} from "@/stores/inspection.js";
import {ref} from "vue";
import axios from "axios";

const siteStore = useSiteStore();
const userStore = useUserStore();
const inspectionStore = useInspectionStore();

const { id, site_id } = storeToRefs(useInspectionStore());
const submitting = ref(false)
const onSubmit = async function (event) {
    event.preventDefault();


    submitting.value = true

    let formData = new FormData(event.target, event.submitter);

    let search = new URLSearchParams(formData);
    let queryString = search.toString();
      var xhr = new XMLHttpRequest();
      xhr.open('GET', event.target.action  + '?' + queryString, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(myBlob);
          link.download = "inspection-report.pdf";
          link.click();
          submitting.value = false
        }
      };
      xhr.send();

};
</script>
