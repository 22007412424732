<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useInspectionsStore } from '../stores/inspections.js';
import { useInspectionStore, inspectionStoreObject } from '../stores/inspection.js';
import { useSitesStore } from '../stores/sites.js';
import { useUserStore } from '../stores/user.js';
import { useAssetTypesStore } from '../stores/assetTypes.js';
import InspectionCard from '@/components/InspectionCard.vue'
import {storeGetterResolver} from '@/helpers/storeCloner.js'
import { useRoute, useRouter } from 'vue-router'

export default {
    components: {
        InspectionCard
    },
    methods: {
        setSite(site){
            this.router.push({name: 'Inspections', params: {site_id: site.id}})
        }
    },
    setup() {

        const router = useRouter()
        const route = useRoute()

        const assetTypesStore = useAssetTypesStore();
        const userStore = useUserStore();
        const inspectionsStore = useInspectionsStore();
        const sitesStore = useSitesStore();
        const storeCloner =  storeGetterResolver(inspectionStoreObject);
        const fuzzySearch = ref('')
        const tab = ref('current')


        return {
            inspectionsStore,
            fuzzySearch,
            InspectionCard,
            tab,
            storeCloner,
            sitesStore,
            router,
            route,
            userStore

        }
    },


}
</script>

<template>
    <v-container id="sites">

        <v-row>

            <v-col cols="12" v-if="userStore.isAdmin()">
                <v-btn class="w-100" prepend-icon="fa-solid fa-circle-plus" :to="{ name: 'Inspection-Create', params:{ site_id: route?.params?.site_id}}">Add New Inspection</v-btn>
            </v-col>

            <v-col cols="6">
                <v-text-field hide-details prepend-inner-icon="fas fa-magnifying-glass" label="Search..." v-model="fuzzySearch"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-select
                    :items="sitesStore.getSites()"
                    :model-value="sitesStore.getSiteById(route.params?.site_id)"
                    @update:modelValue="setSite"
                    item-title="name"
                    item-value="id"
                    return-object
                    label="Site"
                ></v-select>
            </v-col>


            <v-col cols="12">
            <v-tabs
                v-model="tab"
                align-tabs="center"
                color="white"
                >
                <v-tab value="past">Past</v-tab>
                <v-tab value="current">Current</v-tab>
                <v-tab value="future">Future</v-tab>
            </v-tabs>

            </v-col>
            <v-col>
                <v-window v-model="tab">
                    <v-window-item value="past">
                            <v-row style="min-height: 500px">
                                <!-- min height is  height of screen so users can swap between tabs even when there is no results -->
                                <v-col v-if="!inspectionsStore.getInspections(fuzzySearch,'past').length" cols="12" class="px-3" >
                                    <h4>No Inspections to view</h4>
                                </v-col>
                                <v-col v-for="(inspection, key) in inspectionsStore.getInspections(fuzzySearch,'past')" :key="inspectionsStore.$state.deep_rerender + fuzzySearch + key + (route.params?.site_id ? route.params?.site_id: '')" cols="12">
                                    <inspection-card :inspectionStore="inspection"/>
                                </v-col>
                            </v-row>
                    </v-window-item>

                    <v-window-item value="current">
                        <v-row style="min-height: 500px">
                            <!-- min height is  height of screen so users can swap between tabs even when there is no results -->
                            <v-col v-if="!inspectionsStore.getInspections(fuzzySearch, 'current').length" cols="12" class="px-3" >
                                <h4>No Inspections to view</h4>
                            </v-col>
                            <v-col v-for="(inspection, key) in inspectionsStore.getInspections(fuzzySearch, 'current')" :key="inspectionsStore.$state.deep_rerender + fuzzySearch + key + (route.params?.site_id ? route.params?.site_id: '')" cols="12">
                                    <inspection-card :inspectionStore="inspection"/>
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item value="future">
                        <v-row style="min-height: 500px">
                            <!-- min height is  height of screen so users can swap between tabs even when there is no results -->
                            <v-col v-if="!inspectionsStore.getInspections(fuzzySearch,'future').length" cols="12" class="px-3" >
                                <h4>No Inspections to view</h4>
                            </v-col>
                            <v-col v-for="(inspection, key) in inspectionsStore.getInspections(fuzzySearch, 'future')" :key="inspectionsStore.$state.deep_rerender + fuzzySearch + key + (route.params?.site_id ? route.params?.site_id: '')" cols="12">
                                <inspection-card :inspectionStore="inspection"/>
                            </v-col>
                        </v-row>
                    </v-window-item>
                </v-window>
            </v-col>




        </v-row>
    </v-container>
</template>
