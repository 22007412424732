
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import localForage from "localforage";

import { cloneDeep, isEqual } from "lodash";
import router from '../router';
import { useAppStore } from './app';

localForage.config({
    driver: localForage.INDEXEDDB, // This force IndexedDB as the driver
})



const initialState =  {
    user: null
}

export const useUserStore = defineStore('user', {
    //This will store the state in indexedDB, User persistence needs to be bootstrapped before the vue router so we access index db directly
    persistence: true,
    state: () => ( cloneDeep(initialState)),
    getters: {
        user_id: (state) => state.user?.id,
        user_role_id: (state) => state.user?.user_role_id,
        name: (state) => state.user?.forename + ' ' + state.user?.surname
    },
    actions: {
        async initUser () {
            if(this.$state.user == null){
                this.$state = await localForage.getItem('user-state')
            }
        },
        isLoggedIn () {
            return !!this.user
        },

        isAdmin () {
            return this.user_role_id == 1
        },

        async login(username, password) {
            const user = await fetchWrapper.post(`/auth/login`, { username, password });
            // update pinia state
            this.user = user;
            router.push('/inspections');
        },

        async getMe(username, password) {
            const user = await fetchWrapper.post(`/auth/me`, {});
        },

        async validatePassword($v,$externalResults,state,accountSheet, snackbar) {
            Object.assign($externalResults, {current_password: ''})
            if (!await $v.$validate()) return

            const data = await fetchWrapper.post(`/auth/validate-password`, { password: state.current_password });
            if(data['is-valid']) {
                await fetchWrapper.post(`/auth/set-password`, { password: state.new_password })
                accountSheet.show = false
                snackbar.show = true
                state.current_password = ''
                state.new_password = ''
                state.confirm_password = ''
                const appStore =  useAppStore ();
                appStore.setSnackbar("Successfully Changed Password")
                return;
            }

            const errors = {
                current_password: "Your password does not match"
            }
            Object.assign($externalResults, errors)
        },

        logout() {
            this.user = null;
            router.push('/');
        }
    },
})
