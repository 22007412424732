<script>
import { ref, computed, watch } from 'vue';
import { useAssetsStore } from '../stores/assets.js';
import { useSitesStore } from '../stores/sites.js';

export default {
    props: ['modelValue', 'siteId'],
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    setup(props) {
        const assetsStore = useAssetsStore();
        const sitesStore = useSitesStore();
        const searchQuery = ref();
        const debouncedQuery = ref();

        // Debounce the search query
        let debounceTimeout = null;
        watch(searchQuery, (newQuery) => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => {
                debouncedQuery.value = newQuery;
            }, 500); // Adjust the delay as needed
        });

        // Fetch assets based on the debounced query
        const getAssets = computed(() => {
            return assetsStore.getAssets(debouncedQuery.value, props.siteId?.value);
        });

        return {
            assetsStore,
            sitesStore,
            searchQuery,
            debouncedQuery,
            getAssets
        };
    }
};
</script>

<template>
    <v-row>
        <v-col cols="12">
            <h3>Find Asset...</h3>
        </v-col>
        <v-col cols="12">
            <v-text-field :single-line="true" type="text" label="Search" v-model="searchQuery"></v-text-field>
        </v-col>

        <v-col cols="12" v-if="!getAssets.length">
            <p>No results found for that search criteria.</p>
        </v-col>

        <template v-if="debouncedQuery">
            <v-col cols="12" v-if="getAssets.length">
                <p>Click to select...</p>
            </v-col>
            <v-col cols="12" v-for="(asset, index) in getAssets" :key="asset.id + index">
                <v-card @click="value = false" color="primary" class="rounded-xl" :to="{ name: 'Asset-Overview', params: { asset_id: asset.id }}">
                    <v-card-text>
                        <v-row class="align-center">
                            <v-col cols="3" class="flex-shrink-1"><strong>{{ asset?.serial_number }}</strong></v-col>
                            <v-col cols="7">
                                <div>{{ asset?.asset_type?.name }}</div>
                                <div>{{ asset?.location }}</div>
                                <div>{{ sitesStore.getSiteById(asset.site_id)?.name }}</div>
                            </v-col>
                            <v-col cols="2" class="flex-shrink-1"><v-icon icon="far fa-arrow-right" /></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>
