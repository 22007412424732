<template>
   <v-app-bar  :absolute="true"   :class="showPurpleBanner ? 'bg-primaryRed' : 'bg-dark'">

        <v-app-bar-title class="align-self-end mb-2 overflow-visible " ><h4 ><a id="back-button" v-if="backRoute" @click.prevent="back"><v-icon icon="fas fa-chevron-left"></v-icon></a> <span class="">{{title}}</span></h4></v-app-bar-title>
        <template v-slot:append>
        <v-img
        width="100"
        src="/header-top-corner-logo.png"
        ></v-img>
        </template>
   </v-app-bar>
</template>

<script setup>
import { useRoute , useRouter} from "vue-router";
import {ref, watch} from 'vue'

const history = window.history.length
const route = useRoute()
const title = ref(route?.meta?.title)
const backRoute = ref(route?.meta?.backRouteName)
const router = useRouter();
const showPurpleBanner = import.meta.env.VITE_BANNER_STAGING ? true : false;
const back = function () {
  if(window.history.length) {
    router.back()
  }
  else {
    router.push({name: backRoute})
  }
}
  watch(
      () => route.meta.title,
      async newTitle => {
        title.value = newTitle
      },

)
  watch(
      () => route.meta.backRouteName,
      async newBackRouteName => {
        backRoute.value = newBackRouteName
      }
)
</script>
