<script>
import  { qrCode }  from '@/helpers/QrScannerWrapper';
import { ref } from 'vue'
import { useAssetsStore } from '../stores/assets';
import FindAssetBottomSheetVue from './FindAssetBottomSheet.vue';

export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    components:{
        FindAssetBottomSheetVue
    },
    mounted: function() {
    },
        computed: {

        // value: {
        //     get() {
        //         return this.modelValue
        //     },
        //     set(value) {
        //         this.$emit('update:modelValue', value)
        //     }
        // }
    },
    methods: {
        setResults(result) {

            this.cameraResults = result
            this.value = this.cameraResults.data

            this.stateToFind = this.assetsStore.getAssetBySerialNumber(this.cameraResults.data)
            if(this.stateToFind) {
                this.myAssetSheet = false
                this.invalidQR = false
                this.$router.push({name: 'Asset-Overview', params: {asset_id: this.stateToFind?.id}})
            }
            else {
                this.stateToFind = this.assetsStore.getAssetBySerialNumber(String(parseInt(this.cameraResults.data, 10)))
                if(this.stateToFind) {
                    this.myAssetSheet = false
                    this.invalidQR = false
                    this.$router.push({name: 'Asset-Overview', params: {asset_id: this.stateToFind?.id}})
                }
                else {
                    this.invalidQR = true
                }
            }
        },
        startOrStopQrCodeScanner(value ) {
            this.$nextTick(() => {
                if(!this.qrCodeScanner && value){
                    this.qrCodeScanner = qrCode.init(this.$refs.qrFeed, this.setResults)
                    this.qrCodeScanner.start()
                }
                if (this.qrCodeScanner && !value) {
                    this.qrCodeScanner.stop()
                    this.qrCodeScanner = null
                }

            })
        }
    },
    setup() {

        const assetsStore = useAssetsStore()
        return {
            cameraResults: ref(),
            stateToFind: ref(),
            qrCodeScanner: null,
            myAssetSheet: ref(false),
            showFindAsset: ref(false),
            invalidQR: ref(false),
            assetsStore

        }
    },


}
</script>

<template>

    <v-bottom-sheet v-model="myAssetSheet" height="50vh" @update:modelValue="startOrStopQrCodeScanner">
        <template v-slot:activator="{ props }">
            <v-btn block v-bind="props" prepend-icon="far fa-qrcode">Scan Asset</v-btn>
        </template>
        <v-card  color="#EAEAEA" height="100%">
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="cameraResults && cameraResults.data && invalidQR">
                        <h3 class="text-primaryRed"> Invalid QR Code {{this.cameraResults.data}}</h3>
                    </v-col>
                    <v-col cols="12">
                        <video class="w-100" ref="qrFeed"></video>
                    </v-col>
                    <v-col cols="5">
                        Not working?
                    </v-col>
                    <v-col cols="7">
                        <v-bottom-sheet v-model="showFindAsset" height="50vh">
                            <template v-slot:activator="{ props }">
                                <v-btn block v-bind="props"  prepend-icon="far fa-search">Find Asset</v-btn>
                            </template>
                            <v-card color="#EAEAEA" height="100%">
                                <v-card-text>
                                    <FindAssetBottomSheetVue v-model="myAssetSheet" @update:modelValue="showFindAsset = false"></FindAssetBottomSheetVue>
                                </v-card-text>
                            </v-card>
                        </v-bottom-sheet>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>
