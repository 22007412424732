import localForage from "localforage";
import { cloneDeep, isEqual } from "lodash";
import { useRoute, useRouter } from 'vue-router'
import { watch } from 'vue';
import moment from "moment";
import { useAppStore } from '../stores/app';

export async function indexDbPlugin({store,options, app, pinia},) {

    if('persistence' in options && (options.persistence)) {


        // console.log"loggi"

        if(options.persistence == 'file-manual') {

            let filesStore = localForage.createInstance(
                {
                    storeName   : 'files',
                    description : 'files database'
                });
            let storedFiles = [];
            filesStore.iterate(function(value, key, iterationNumber) {
                // Resulting key/value pair -- this callback
                // will be executed for every item in the
                // database.
                storedFiles.push(value);

            }).then(function() {
                store.$state.files = storedFiles
            }).catch(function(err) {
                // This code runs if there were any errors
                console.log(err);
            });
            store.$subscribe(() => {
                console.log("Setting subscribe function");
                console.log(store.$state.files);

                for (let index = 0; index < store.$state.files; index++) {
                    console.log("putting in files store");
                    const file = store.$state.files[index];
                    filesStore
                    .setItem(file.id, { ...cloneDeep(file) })

                }
            });
        }
        else {
            localForage.config({
                driver: localForage.INDEXEDDB, // This force IndexedDB as the driver
            })
            localForage.getItem(store.$id + '-state').then((stored) => {

                const appStore = useAppStore()
                if (stored) {
                    store.$state = stored
                }

            })

            store.$subscribe(() => {
                localForage
                .setItem(store.$id + '-state', { ...cloneDeep(store.$state) }) // Destructure to transform to plain object
            })
        }

    }

    options.actions.delete = () => store.deleted_at = moment();

    //This will allow a state to be initialized after a state has been synced from storage
    //Main use for this is edit pages
    if('initByRoute' in options && typeof options.initByRoute == 'function') {
        app.mixin({
            updated() {
                if(!app.config.storeUpdated) {
                    store.$patch(options.initByRoute( store.router.currentRoute.value))

                }
                app.config.storeUpdated = true
            }
        })
        store.router.afterEach((to,from,next) => {
            store.$patch(options.initByRoute(store.router.currentRoute.value))
        });
    }
}
