<template>
    <v-card :title="site?.name">
        <v-card-text>
            <v-row>
                <v-col v-if="title" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-clipboard"></v-icon-primary-red> <h2>{{title}}</h2></v-col>
                <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-calendar"></v-icon-primary-red><span>{{date_range}}</span></v-col>

                <v-col cols="9">
                    <v-btn class="w-100" :class="isAdmin() ? 'h-100' : ''" prepend-icon="fas fa-clipboard-list-check" :to="{ name: 'Inspection', params: { inspection_id: id }}" style="line-height:48px;">View Inspection</v-btn>
                </v-col>
                <v-col cols="3" v-if="isAdmin()">
                    <v-btn-outline  class="w-100" rounded="rounded" icon="fas fa-edit" :to="{ name: 'Inspection-Edit', params: { inspection_id: id }}"></v-btn-outline>
                </v-col>
                
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { defineProps } from 'vue'
import { defineStore,storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { useUserStore } from '@/stores/user';
import { useInspectionsStore } from '@/stores/inspections';
import { deleteNow} from '../helpers/FilterWrapper.js';

const userStore = useUserStore()
const {isAdmin} =  userStore
const inspectionsStore = useInspectionsStore()

const props = defineProps (['inspectionStore'])

//THIS BECOMES READ ONLY IT IS SIMPLY ONLY TO GET METHODS ASSOCIATED WITH THE INSPECTION STORE MODULE
const inspectionStore =  props.inspectionStore 
const {id,title,instruction, inspectors, site} = inspectionStore
const date_range = inspectionStore.date_from_formatted == inspectionStore.date_to_formatted ? inspectionStore.date_from_formatted : inspectionStore.date_from_formatted+ ' - ' + inspectionStore.date_to_formatted

//THIS ISNT READONLY
</script>
