<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useUsersStore } from '../stores/users.js';
import moment from 'moment';
import { deleteNow } from '../helpers/FilterWrapper.js';


export default {
    setup() {
        const usersStore = useUsersStore();
        const fuzzySearch = ref('')


        return {
            usersStore,
            fuzzySearch,
            deleteNow
        }
    },


}
</script>

<template>
    <v-container id="sites">

        <v-row>

            <v-col cols="12">
                <v-btn class="w-100" prepend-icon="fa-solid fa-circle-plus" :to="{ name: 'User-Create'}">Add New User</v-btn>
            </v-col>

            <v-col cols="12">
                <v-text-field hide-details prepend-inner-icon="fas fa-magnifying-glass" label="Search..." v-model="fuzzySearch"></v-text-field>
            </v-col>

            <v-col v-if="!usersStore.getUsers(fuzzySearch).length" cols="12" >
                <h4>No Users to view</h4>
            </v-col>
            <template v-for="(user, key) in usersStore.getUsers(fuzzySearch)" :key="usersStore.$state.deep_rerender + key + user.id">
                <v-col align-self="stretch" cols="6">

                    <v-card class="h-100">

                        <v-card-text class="d-flex h-100 flex-column justify-space-between">
                            <h2 class="e mb-1">
                                {{ user.forename + ' ' + user.surname }}
                            </h2>
                            <div class="">
                                <p class="mb-2">{{user.username}}</p>
                                <v-row>
                                    <v-col cols="6">
                                        <v-btn-outline class="w-100" rounded="rounded" :to="{ name: 'User-Edit', params: { user_id: user.id }}" icon="fas fa-edit fa-xs"></v-btn-outline>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-btn-outline  class="w-100" color="primaryRed" rounded="rounded" @click="deleteNow(user, 'user')" icon="fas fa-trash fa-xs"></v-btn-outline>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>

        </v-row>
    </v-container>
</template>
