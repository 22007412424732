
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';
import {filterState} from '../helpers/FilterWrapper';
import {useAppStore} from './app.js'
import {useRoute} from 'vue-router';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    inspections: [],
    deep_rerender: uuidv4(),
}

export const useInspectionsStore = defineStore('inspections', {
    persistence: true,
    state: () => ( initialState),
    getters: {
        getInspectionById: (state) => {
            return (inspectionId) => {
                return state.inspections.find((inspection) => inspection.id === inspectionId)
            }
        },
        getInspections: (state) => {
            return (fuzzyText, dateStatus) => {
                const route = useRoute()
                return filterState(state.inspections).deletedFilter().dateToFilter(dateStatus).byUser().siteFilter(route.params?.site_id).fuzzyFilter(fuzzyText).sortAsc('site.name').get();
            }
        },
        getInspectionAssets: (state) => {
            return (inspectionId,assetTypeId) => {
                return filterState(state.getInspectionById(inspectionId).assets).byAssetType(assetTypeId).get();
            }
        },
        getInspectionAssetsPresentInAssetGrades: (state) => {
            return (inspectionId,assetTypeId) => {
                return filterState(state.getInspectionById(inspectionId).assets).byAssetType(assetTypeId).presentInAssetGrades(inspectionId).sortAsc('location').get();
            }
        },
        getInspectionAssetsNotPresentInAssetGrades: (state) => {
            return (inspectionId,assetTypeId) => {
                return filterState(state.getInspectionById(inspectionId).assets).byAssetType(assetTypeId).notPresentInAssetGrades(inspectionId).sortAsc('location').get();
            }
        },
        getsInspectionsByAssetWithoutSurvey: (state) => {
            return (asset_id) => {
                return filterState(state.inspections).deletedFilter().hasAssetForSite(asset_id).hasAssetTypeForAsset(asset_id).inspectionNotPresentInAssetGrades(asset_id).get();
            }
        },
        getCurrentInspectionByAssetId: (state) => {
            return (asset_id) => {
                return filterState(state.inspections).deletedFilter().dateToFilter('current').hasAssetForSite(asset_id).hasAssetTypeForAsset(asset_id).first();
            }
        },
        getInspectionsBySiteId: (state) => {
            return (siteId) => {
                return filterState(state.inspections).deletedFilter().siteFilter(siteId).get()
            }
        }
    },
    actions: {
        async sync() {
          await fetchWrapper.sync(`/inspections/index`,`/inspections/update-or-create` ,{
              stored: this.inspections.map(function (inspection) { return {id:inspection.id, updated_at: inspection.updated_at}}),
          }, this.inspections);
        },

        saveInspection(inspection) {
            let inspectionIndex = this.$state.inspections.findIndex((inspectionToFind) => inspectionToFind.id === inspection.id)
            inspection.updated_at = moment().format()
            if(this.inspections[inspectionIndex] ) {
                this.inspections[inspectionIndex] = inspection
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully saved changes")
            } else {
                this.inspections.push(inspection)
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully created Inspection")
            }
        },

        closeById(id) {
            const index = this.$state.inspections.findIndex((inspec) => inspec.id == id)
            this.$state.inspections[index].updated_at = moment().format()
            this.$state.inspections[index].closed_at = moment().format()
            this.$state.deep_rerender = uuidv4()
            const appStore = useAppStore()
            const vm = this
            appStore.setSnackbar("Successfully closed/locked inspection",function(){

                vm.$state.inspections[index].updated_at = moment().format()
                vm.$state.inspections[index].closed_at = null
                vm.$state.deep_rerender = uuidv4()
            })

        },
        deleteById(id) {
            const index = this.$state.inspections.findIndex((inspec) => inspec.id == id)
            this.$state.inspections[index].updated_at = moment().format()
            this.$state.inspections[index].deleted_at = moment().format()
            this.$state.deep_rerender = uuidv4()
            const appStore = useAppStore()
            const vm = this
            this.router.push({name: 'Inspections'})
            appStore.setSnackbar("Successfully deleted inspection",function(){

                vm.$state.inspections[index].updated_at = moment().format()
                vm.$state.inspections[index].deleted_at = null
                vm.$state.deep_rerender = uuidv4()
                vm.router.push({name: 'Inspections'})
            })

        }
    }
})
