
import { defineStore } from 'pinia'
import { cloneDeep, isEqual } from "lodash";


const initialState = {
    loaded: false,
    snackbar: {
        show: false,
        message: '',
        timeout: 5000,
        undoMethod: null
    }
}

export const useAppStore = defineStore('app', {
    state: () => (cloneDeep(initialState)),

    actions: {
        setSnackbar(message,undoCallback) {
            this.$state.snackbar.message = message
            this.$state.snackbar.undoMethod = undoCallback
            this.$state.snackbar.show = true
        },
        setLoaded()
        {
            this.$state.loaded = true;
        },
        undo() {
            this.$state.snackbar.undoMethod()
            this.$state.snackbar.show = false
        }
    }
})
