<template>
    <v-card :title="'Inspection'">
        <v-card-text>
            <v-row>
                <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-calendar"></v-icon-primary-red><span>{{date_range}}</span></v-col>
                <v-col v-if="title" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-clipboard"></v-icon-primary-red> <strong>{{title}}</strong></v-col>
                <v-col v-if="instruction" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-comment"></v-icon-primary-red><span>{{instruction }}</span></v-col>
                <v-col v-if="inspectors?.length"  cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-user"></v-icon-primary-red><span>{{inspectors_names}}</span></v-col>

                <v-col cols="12" v-if="isAdmin()">
                    <v-btn-outline class="w-100" prepend-icon="fas fa-edit" :to="{ name: 'Inspection-Edit', params: { inspection_id: id }}">Edit</v-btn-outline>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { defineProps } from 'vue'
import { storeToRefs } from 'pinia';
import { useInspectionStore } from '@/stores/inspection.js';
import { useUserStore } from '@/stores/user';

const inspectionStore = useInspectionStore()
const userStore = useUserStore()
const {inspectors_names, date_range} =  inspectionStore
const {id,title,instruction, inspectors} = storeToRefs(inspectionStore)
const {isAdmin} =  userStore

</script>
