<script>
import { storeToRefs } from 'pinia';
import { ref, reactive } from 'vue'
import {useUserModelStore, rules} from '../stores/userModel'
import { useVuelidate } from '@vuelidate/core'
import { useRoute } from 'vue-router';
import { useUserStore } from '../stores/user';
import { email, required, sameAs } from '@vuelidate/validators'
import { helpers } from '@vuelidate/validators'
import localForage from "localforage";

export default {
    setup() {
        const userStore = useUserStore()
        const user  = storeToRefs(userStore);
        const state = reactive({current_password:"", new_password: "", confirm_password: ""})
        const accountSheet = reactive({show: false})
        const snackbar = reactive({show: false})
        const externalResults = ref({ current_password: ''})
        const v$ = useVuelidate({
        current_password: {required},

        new_password: { required},
        confirm_password: { checkPassword: helpers.withMessage('Passwords do not match', function(value) {
            return state.new_password== value

        })},
        }, state, {$externalResults: externalResults})
        const resetAppCheck = ref(false);
        const resetApp = function () {
            localForage.config({
                driver: localForage.INDEXEDDB, // This force IndexedDB as the driver
            })
            localForage.clear().then(function() {
                console.log("SUCCESSFULLY WIPED APP");
                window.location.reload(true);
            });
        }
        // expose the ref to the template
        return {
            ...user,
            userStore,
            v$,
            state,
            externalResults,
            accountSheet,
            resetAppCheck,
            snackbar,
            resetApp
        }
    },


}
</script>

<template>
    <v-container id="account">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    Name:
                                </v-col>
                                <v-col cols="8">
                                    <strong>{{user.forename + ' ' + user.surname}}</strong>
                                </v-col>
                                <v-col cols="4">
                                    Username:
                                </v-col>
                                <v-col cols="8">
                                    <strong>{{user.username}}</strong>
                                </v-col>
                                <v-col cols="4">
                                    Password:
                                </v-col>
                                <v-col cols="8">
                                    <v-bottom-sheet v-model="accountSheet.show">
                                        <template v-slot:activator="{ props }">
                                            <v-btn block v-bind="props" prepend-icon="fas fa-lock-keyhole">Change Password</v-btn>
                                        </template>
                                        <v-card :title="'Change password'">
                                            <v-card-text>
                                                <v-form fast-fail @submit.prevent="userStore.validatePassword(v$, externalResults, state, accountSheet, snackbar)">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field label="Current Password *"  type="password" required v-model="state.current_password"  :error-messages="v$.current_password.$errors.map(e => e.$message)" @input="v$.current_password.$touch" @blur="v$.current_password.$touch"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field label="New Password *"  type="password" required v-model="state.new_password"  :error-messages="v$.new_password.$errors.map(e => e.$message)" @input="v$.new_password.$touch" @blur="v$.new_password.$touch"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field label="Confirm New Password *" type="password" v-model="state.confirm_password" :error-messages="v$.confirm_password.$errors.map(e => e.$message)" @input="v$.confirm_password.$touch" @blur="v$.confirm_password.$touch"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-btn block type="submit" prepend-icon="fas fa-save">Save Password</v-btn>
                                                    </v-col>
                                                </v-row>
                                                </v-form>
                                            </v-card-text>
                                        </v-card>
                                    </v-bottom-sheet>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-snackbar  class="mb-5" attach="main" color="white" v-model="snackbar.show" :timeout="timeout">
                    Successfully changed password
                </v-snackbar>
                <v-col cols="12">
                    <v-btn @click="userStore.logout" class="w-100" prepend-icon="fa-solid fa-right-from-bracket">Log Out</v-btn>
                </v-col>
                <v-col cols="12">
                    <v-bottom-sheet >
                            <template v-slot:activator="{ props }">
                                <v-btn-outline block color="primaryRed" v-bind="props" class="bg-white" prepend-icon="fa-solid fa-refresh"> Reset App</v-btn-outline>
                            </template>

                            <v-card >
                                <v-card-text>
                                    <h3>Reset App</h3>
                                    <p>In rare circumstances you may need to re-set your app.
                                    This will clear your local cache and download updates.
                                    You should only do this if directed to by the development team.
                                    Clicking this button will log you out and any data you’ve entered recently could be lost.
                                    To re-set please check the box below.</p>
                                    <v-form @submit.prevent="resetApp">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-checkbox v-model="resetAppCheck " label="I confirm this will clear my local data and anything not saved may be lost"></v-checkbox>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-btn :disabled="!resetAppCheck " type="submit"  block prepend-icon="fas fa-circle-check">Reset app</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>

                                </v-card-text>
                            </v-card>
                        </v-bottom-sheet>
                </v-col>
            </v-row>
    </v-container>
</template>
