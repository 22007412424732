import { useUserStore } from '@/stores/user';
import { cloneDeep } from 'lodash';

import { v4 as uuidv4 } from 'uuid';
export const storeGetterResolver =  (store) => {  

    return {
        store: {...store},
        resolve
    }
}
    

const resolve = function (data) {
    
    const store  = {...this.store };
    store.state = () => ({...data});
    delete store.initByRoute

    return  store;
}
