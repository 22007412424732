<template>
  <v-container class="fill-height d-flex justify-start mb-6">
    <v-responsive class="align-center text-center fill-height">
    <v-img v-if="submitting" position="relative"  src="/blob-double-full-colour.svg" alt=""  style="    position: absolute;height: 100vh;width: 100vw;top: 0;"/>
        <form  @submit.prevent="onSubmit">
            <v-row>
                <v-col cols="12">
                    <h1 class="text-shadow">Log In</h1>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="username"
                        label="Username"
                        required
                        :error-messages="errors.user"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                <v-text-field
                    v-model="password"
                    type="password"
                    label="Password"
                    hint="If you have forgotten your password please contact the admin"
                ></v-text-field>
                </v-col>
                <v-col cols="12">
                <v-btn type="submit" class="w-100" append-icon="fa-solid fa-right-to-bracket" :loading="submitting">
                Log In
                </v-btn>

                </v-col>
            </v-row>
        </form>
    </v-responsive>
  </v-container>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useUserStore } from '@/stores/user';

const username = ref('')
const password = ref('')
const submitting = ref(false)
const errors = ref({user: null})

const onSubmit = function () {
    submitting.value = true
    const authStore = useUserStore();

    return authStore.login(username.value, password.value).then(() => {submitting.value = false})
    .catch((error) => {
        submitting.value = false;
        errors.value.user = 'Invalid login';
        throw error
    });
}


</script>
