<template>
    <v-card :title="'Location'" v-if="site">
        <v-card-text>
            <v-row>


                <v-col v-if="site.name" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-location-dot"></v-icon-primary-red> <span>{{site.name}}</span></v-col>
                <v-col v-if="site.address" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-map-location"></v-icon-primary-red><span>{{site.address}}</span></v-col>
                <v-col v-if="site.notes" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-note"></v-icon-primary-red><span>{{site.notes}}</span></v-col>
                <v-col cols="12" v-if="isAdmin()">
                    <v-btn-outline class="w-100" prepend-icon="fas fa-location-dot" :to="{ name: 'Site', params: { site_id: site.id }}">View Site</v-btn-outline>
                </v-col>

            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { defineProps } from 'vue'
import { storeToRefs } from 'pinia';
import { useInspectionStore } from '@/stores/inspection.js';
import { useUserStore } from '@/stores/user';

const inspectionStore = useInspectionStore()
const userStore = useUserStore()
const {dateRange} = inspectionStore
const {site} = storeToRefs(inspectionStore)
const {isAdmin} =  userStore

</script>
