<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import {useUserModelStore, rules} from '../stores/userModel'
import { useVuelidate } from '@vuelidate/core'
import { useRoute } from 'vue-router';

export default {
    setup() {
        const userModelStore = useUserModelStore()
        const userModel = storeToRefs(userModelStore);
        const route = useRoute()

        const currentUsername = userModel.username.value
        const v$ = useVuelidate(rules(userModelStore.$state,route, currentUsername), userModel)

        // expose the ref to the template
        return {
            ...userModel,
            window,
            userModelStore,
            v$
        }
    },
}
</script>

<template>
    <v-container id="site">
        <v-form fast-fail @submit.prevent="userModelStore.saveUser(v$)">
        <v-row>
            <v-col cols="12">
                <v-text-field label="Forename *" required v-model="forename"  :error-messages="v$.forename.$errors.map(e => e.$message)" @input="v$.forename.$touch" @blur="v$.forename.$touch" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Surname *" required v-model="surname" :error-messages="v$.surname.$errors.map(e => e.$message)" @input="v$.surname.$touch" @blur="v$.surname.$touch" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Email address"  v-model="email_address" :error-messages="v$.email_address.$errors.map(e => e.$message)" @input="v$.email_address.$touch" @blur="v$.email_address.$touch" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Username *" required v-model="username" :error-messages="v$.username.$errors.map(e => e.$message)" @input="v$.username.$touch" @blur="v$.username.$touch" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Password *"  type="password" required v-model="password"  :error-messages="v$.password.$errors.map(e => e.$message)" @input="v$.password.$touch" @blur="v$.password.$touch" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Confirm Password *" type="password" v-model="confirm_password" :error-messages="v$.confirm_password.$errors.map(e => e.$message)" @input="v$.confirm_password.$touch" @blur="v$.confirm_password.$touch" autocomplete="off"></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-select
                    v-model="user_role_id"
                    :items="window.document.dropdownOptions.UserRoles"
                    item-title="name"
                    item-value="id"
                    label="Role"
                    :error-messages="v$.user_role_id.$errors.map(e => e.$message)"
                    @input="v$.user_role_id.$touch" @blur="v$.user_role_id.$touch"
                    ></v-select>
            </v-col>
            <v-col cols="12">
                <v-select
                    v-model="status"
                    :items="window.document.dropdownOptions.UserStatus"
                    label="Status"
                    @input="v$.status.$touch" @blur="v$.status.$touch"
                    :error-messages="v$.status.$errors.map(e => e.$message)"></v-select>
            </v-col>
            <v-col cols="12">
                <v-btn type="submit" class="w-100" prepend-icon="fa-solid fa-save">{{$route.params?.user_id ? "Save Changes" : "Save User"}}</v-btn>
            </v-col>
        </v-row>
        </v-form>
    </v-container>

</template>
