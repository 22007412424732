<template>
    <v-app class="default-layout" >
        <v-container v-if="!loaded" class="fill-height d-flex justify-start mb-6">
                <v-responsive class="align-center text-center fill-height">
                    <v-img position="relative"  src="/blob-double-full-colour.svg" alt=""  style="    position: absolute;height: 100vh;width: 100vw;top: 0;"/>
                </v-responsive>
        </v-container>
        <template v-else>

        <default-header />

        <default-view />

        <default-bar />
        </template>
    </v-app>
</template>

<script>
import { storeToRefs } from 'pinia';
import DefaultHeader from './Header.vue'
import DefaultBar from './AppBar.vue'
import DefaultView from './View.vue'
import { useAppStore } from '../../stores/app';
export default {
    components: {
        DefaultHeader,
        DefaultBar,
        DefaultView
    },
    mounted () {


    },

    setup () {

        const appStore = useAppStore();
        const app = storeToRefs(appStore)


        return {
            appStore,
            ...app
        }

    }
}
</script>
