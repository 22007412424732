import {defineStore} from 'pinia'
import localForage from "localforage";
import {ref} from "vue";
import {fetchWrapper} from "../../helpers/fetchWrapper.js";
import {cloneDeep} from "lodash";

export const useHubStore = defineStore('hub', {
    persistence: true,
    state: () => ({
        asset: null,
    }),
    getters: {
        assetGradesWithInspection: (state) => {
            return state.asset?.asset_grades?.filter(assetGrade => assetGrade.inspection !== null)
        },
        assetGradesWithoutInspection: (state) => {
            return state.asset?.asset_grades?.filter(assetGrade => assetGrade.inspection === null)
        }
    },
    actions: {
        async getAssetBySerialNumber (serialNumber) {
            this.$state.asset = await fetchWrapper.post(`/hub-assets/getAsset/` + serialNumber);
        },
        async getReports() {
            this.router.push(`/hub-inspection-reports/${this.$state.asset.serial_number}`)
        },

    },
})
