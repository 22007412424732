import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { useRoute, useRouter } from 'vue-router'
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';


const initialState = {
    files: []
}

export const useFilesStore = defineStore('files', {
    persistence: true,
    state: () => ( cloneDeep(initialState)),
    getters: {
        getFileById: (state) => {
          return (fileId) => {
            return state.files.find((file) => file.id === fileId)
          }
        },
    },
    actions: {
        async sync() {
            fetchWrapper.sync(`/file/index`,`/file/update-or-create` ,{
                stored: this.files.map(function (file) { return {id:file.id, updated_at: file.updated_at}}),
            }, this.files)
        },

        saveFile(file) {
            let fileIndex = this.$state.files.findIndex((fileToFind) => fileToFind.id === file.id)
            file.updated_at = moment().format()

            if(this.files[fileIndex] ) {
                this.files[fileIndex] = file
            } else {
                this.files.push(file)
            }
        }
    }
})
