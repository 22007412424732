<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useSitesStore } from '../stores/sites.js';
import { useAssetTypesStore } from '../stores/assetTypes.js';
import { useUsersStore } from '../stores/users.js';
import { useInspectionStore, rules } from '../stores/inspection.js';
import {  } from '../stores/inspection.js';
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment';
import {useRoute} from 'vue-router';
import { useVuelidate } from '@vuelidate/core'


export default {
    components: {
        DatePicker
    },
    methods: {
        setClosedAt(value) {
            if(value != 'Open' ) {
                this.closed_at = moment().format()
            }
            else {
                this.closed_at = null
            }

            this.status = value
        },

        setSite(value) {
            this.site = value;
            this.site_id = value?.id
        }
    },
    setup() {
        const route = useRoute()
        const sitesStore = useSitesStore()
        const assetTypesStore = useAssetTypesStore()
        const usersStore = useUsersStore()
        const inspectionStore = useInspectionStore()
        const inspection = storeToRefs(inspectionStore);
        const v$ = useVuelidate(rules(inspectionStore.$state,route), inspection)


        // expose the ref to the template
        return {
            ...inspection,
            sitesStore,
            assetTypesStore,
            inspectionStore,
            usersStore,
            route,
            v$
        }
    },

    mounted () {
        const site = this.sitesStore.getSiteById(this.route?.params?.site_id)
        if(site) {
            this.setSite(site)
        }
    }

}
</script>

<template>
    <v-container id="site">
        <v-form fast-fail @submit.prevent="inspectionStore.saveInspection(v$)">
            <v-row>
                <v-col cols="12">
                    <v-select
                        :error-messages="v$.site.$errors.map(e => e.$message)" @input="v$.site.$touch" @blur="v$.site.$touch"
                        :items="sitesStore.getSites()"
                        :model-value="site"
                        @update:modelValue="setSite"
                        item-title="name"
                        item-value="id"
                        required
                        return-object
                        label="Site *"
                    ></v-select>
                </v-col>
                <v-col cols="6">
                    <date-picker label="Date From *" v-model="date_from" required :error-messages="v$.date_from.$errors.map(e => e.$message)" @input="v$.date_from.$touch" @blur="v$.date_from.$touch"></date-picker>
                </v-col>
                <v-col cols="6">
                    <date-picker label="Date to *" v-model="date_to"  :error-messages="v$.date_to.$errors.map(e => e.$message)" @input="v$.date_to.$touch" @blur="v$.date_to.$touch"></date-picker>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Inspection title *" required v-model="title"  :error-messages="v$.title.$errors.map(e => e.$message)" @input="v$.title.$touch" @blur="v$.title.$touch"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-select
                        item-title="name"
                        item-value="id"
                        return-object
                         :error-messages="v$.asset_types.$errors.map(e => e.$message)" @input="v$.asset_types.$touch" @blur="v$.asset_types.$touch"
                        v-model="asset_types" chips multiple required label="Asset Types *" :items="assetTypesStore.getAssetTypes()"></v-select>
                </v-col>
                <v-col cols="12">
                    <v-textarea label="Scope *" required v-model="scope"  :error-messages="v$.scope.$errors.map(e => e.$message)" @input="v$.scope.$touch" @blur="v$.scope.$touch"></v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-textarea label="Instructions for Inspector" v-model="instruction"  :error-messages="v$.instruction.$errors.map(e => e.$message)" @input="v$.instruction.$touch" @blur="v$.instruction.$touch"></v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-select
                        item-title="forename"
                        item-value="id"
                        return-object
                         :error-messages="v$.inspectors.$errors.map(e => e.$message)" @input="v$.inspectors.$touch" @blur="v$.inspectors.$touch"
                        v-model="inspectors" chips multiple required label="Inspectors *" :items="usersStore.getUsers()"></v-select>
                </v-col>
                <v-col cols="12">
                    <v-select
                        :error-messages="v$.status.$errors.map(e => e.$message)" @input="v$.status.$touch" @blur="v$.status.$touch"
                        :model-value="status"
                        @update:modelValue="setClosedAt"
                        :items="['Open', 'Closed/Locked']"
                        label="Status *"
                        required
                    ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-btn type="submit" class="w-100" prepend-icon="fa-solid fa-save">{{route.params?.inspection_id ? "Save Changes" : "Save Inspection"}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>

</template>
