<script>
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue'
import { useAssetTypesStore } from '../stores/assetTypes.js';
import { useAssetStore, rules } from '../stores/asset.js';
import QuestionsCarousel from '../components/QuestionsCarousel.vue';
import { useSurveyAnswersStore } from '../stores/surveyAnswers.js';
import { useRoute } from 'vue-router';

import { useAssetGradesStore } from '../stores/AssetGrades.js';
import moment from 'moment';
import { filterState } from '../helpers/FilterWrapper.js';
import { useAssetGradeStore } from '../stores/AssetGrade.js';

export default {
    components: {
        QuestionsCarousel
    },
    methods: {

        subtractIndex() {
            if(this.questionIndex > 0){
                this.questionIndex -= 1
            }
            else {
                this.questionIndex = 0
            }
        },
        addIndex() {

            if(this.questionIndex < this.questions.length-1){
                this.questionIndex += 1
            }
            else {
                this.questionIndex = this.questions.length-1
            }
        },
        saveToSurveyAnswers() {
            const surveyAnswers = useSurveyAnswersStore()
            surveyAnswers.$reset()
            surveyAnswers.initViaPreviousAssetGrade(this.asset)

            const assetGradeStore = useAssetGradeStore()
            assetGradeStore.initViaPreviousAssetGrade(this.asset)
            this.$router.push({name: "Asset-Survey-Answers", params:{asset_id: this.asset.id.value, inspection_id: this.$route?.params?.inspection_id}})

        },
        saveToAssetGrades() {
            this.assetGradesStore.clonePrevious(this.asset)
            this.$router.push({name: 'Asset-Overview',  params:{asset_id: this.asset.id.value, inspection_id: this.$route?.params?.inspection_id}})
        }
    },
    data () {
        const route = useRoute();

        // expose the ref to the template
        return {
            route
        }
    },

    setup () {
        const answersStore = useSurveyAnswersStore()
        answersStore.resetState()

        const assetGradesStore = useAssetGradesStore()
        const assetStore = useAssetStore()
        const asset = storeToRefs(assetStore);
        const questionIndex = ref(0)
        const assetTypesStore = useAssetTypesStore()

        const questions = computed(() =>{
            return assetTypesStore.getQuestionsByAssetTypeId(asset.asset_type_id.value)
        })

        const latestAssetGradeWithAnswersForAssetId = assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value)

        const lastestAssetGradeAnswers =  computed(() =>{
            return filterState(latestAssetGradeWithAnswersForAssetId?.answers).where('question_answer_type.question_id', assetTypesStore.getQuestionsByAssetTypeId(asset.asset_type_id.value)[questionIndex.value]?.id).sortAsc('question_answer_type.order').get()
        })


        return {

            asset,
            assetGradesStore,
            moment,
            questions,
            questionIndex,
            assetTypesStore,
            filterState,
            latestAssetGradeWithAnswersForAssetId,
            lastestAssetGradeAnswers,
            answersStore
        }
    }

}
</script>

<template>
    <div>
    <v-container id="site" style="background-color: rgb(var(--v-theme-surface))">
        <v-row>
            <v-col cols="6">
                <v-bottom-sheet>
                    <template v-slot:activator="{ props }">
                        <v-btn-outline style="background-color: white" border="3" block v-bind="props" text="Cancel" prepend-icon="fas fa-xmark"></v-btn-outline>
                    </template>

                    <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <h3>Are you sure you wish to cancel?</h3>
                            </v-col>
                            <v-col cols="12">
                                Any progress you've made will be lost
                            </v-col>
                            <v-col cols="12">
                            <v-btn block prepend-icon="fas fa-circle-xmark" :to="{name:'Asset-Overview', params: {asset_id: asset.id.value, inspection_id: route.params.inspection_id}}" > Cancel Survey</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-bottom-sheet>
            </v-col>
            <v-col cols="6">
                <v-bottom-sheet>
                    <template v-slot:activator="{ props }">
                        <v-btn-outline style="background-color: white" border="3" block v-bind="props" prepend-icon="fas fa-circle-info" >Asset Info</v-btn-outline>
                    </template>
                    <v-card :title="asset?.asset_type?.name">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-hashtag"></v-icon-primary-red><span>{{asset?.serial_number}}</span></v-col>
                                <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-location-dot"></v-icon-primary-red><span>{{asset?.location}}</span></v-col>
                                <v-col cols="12" v-if="assetGradesStore.getLatestAssetGradeForAssetId(asset.id.value)">
                                <div class="d-flex">
                                Latest grade:
                                <span class="mx-4">
                                <v-radio-group style="max-width: 40px" inline class="inside-lable" v-model="assetGradesStore.getLatestAssetGradeForAssetId(asset.id.value).grade">
                                    <v-grade-green-radio v-if="assetGradesStore.getLatestAssetGradeForAssetId(asset.id.value).grade == 'pass'" value="pass" label="Pass"></v-grade-green-radio>
                                    <v-grade-red-radio  v-if="assetGradesStore.getLatestAssetGradeForAssetId(asset.id.value).grade == 'fail'" value="fail" label="Fail"></v-grade-red-radio>
                                </v-radio-group>
                                </span>
                                {{moment(assetGradesStore.getLatestAssetGradeForAssetId(asset.id.value)?.graded_at).format('(DD/MM/YYYY)')}}
                                </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-bottom-sheet>
            </v-col>
            <v-col cols="12" v-if="assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value)">
                <v-bottom-sheet>
                    <template v-slot:activator="{ props }">
                        <v-btn-outline style="background-color: white" border="3" block v-bind="props"  prepend-icon="fas fa-circle-check">Auto-Complete Using Previous Survey</v-btn-outline>
                    </template>

                    <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <h3>Auto-Complete Using Previous Survey</h3>
                            </v-col>
                            <v-col cols="12">
                                <v-btn block prepend-icon="fas fa-eye" @click="saveToSurveyAnswers">View And Edit Before Saving</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn-outline block prepend-icon="fas fa-save" @click="saveToAssetGrades">Save Without Editing Responses</v-btn-outline>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-bottom-sheet>
            </v-col>
        </v-row>
    </v-container>

    <v-container id="site">
        <v-row     v-touch="{
        left: () => addIndex(),
        right: () => subtractIndex(),
        }">
            <v-col cols="12" class="text-center">
                <v-progress-linear color="primaryRed" bg-color="grey" v-model="questionIndex" :height="3" :max="questions?.length"></v-progress-linear>

                <small class="text-center text-fontDark">Question {{questionIndex + 1}} of {{questions?.length}}</small>
            </v-col>
            <v-col cols="12">
                <questions-carousel :questionIndex="questionIndex"></questions-carousel>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col :cols="questionIndex != questions?.length -1 ? '6' : '4' " class="text-right">
                        <v-btn variant="elevated" :disabled="questionIndex == 0" @click="subtractIndex" rounded="rounded" icon="fas fa-chevron-left"></v-btn>
                    </v-col>
                    <v-col cols="6" v-if="questionIndex != questions?.length -1">
                        <v-btn variant="elevated" :disabled="questionIndex == questions?.length -1" @click="addIndex" rounded="rounded" icon="fas fa-chevron-right"></v-btn>
                    </v-col>
                    <v-col cols="8" v-else>
                        <div class="h-100">
                            <v-btn style="min-height: 50px;" variant="elevated" class="h-100" :to="{name: 'Asset-Survey-Answers', params: {asset_id:asset.id.value , inspection_id: route?.params?.inspection_id}}"> Complete Survey</v-btn>
                        </div>
                    </v-col>

                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card v-if="latestAssetGradeWithAnswersForAssetId && lastestAssetGradeAnswers?.length" >
                    <v-card-text>
                        <h3>Previous Response: ({{moment(latestAssetGradeWithAnswersForAssetId?.graded_at).format('DD/MM/YYYY')}}):</h3>

                        <template v-for="(answer, index) in lastestAssetGradeAnswers"  :key="'container'+index" >

                            <v-row>
                                <component :disabled="true" :answer="answer" :surveyAnswersStore="answersStore" :is="answer.question_answer_type.answer_type.component_name" v-bind="JSON.parse(answer.question_answer_type.answer_type.props)" :key="answer.question_answer_type.question_id+answer.question_answer_type.answer_type.component_name" :question="answer.question_answer_type.question" :answerTypeId="answer.question_answer_type.answer_type_id" />

                            </v-row>
                        </template>



                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </div>

</template>
