<template>

        <v-menu
            ref="open"
            :close-on-content-click="false"
            v-model="open"
            >
            <template v-slot:activator="{ props }">
                <v-text-field
                :disabled="disabled"
                :label="label"
                :prepend-inner-icon="prependInnerIconData"
                :append-inner-icon="appendInnerIconData"
                placeholder="dd/mm/yyyy"
                readonly
                v-model="dateFormatted"
                v-bind="props"
                class="w-100"
                ></v-text-field>
            </template>

            <v-date-picker v-model="date" no-title @update:modelValue="open = false" @click:cancel="open = false"></v-date-picker>
        </v-menu>
</template>

<script>
import moment from 'moment';
export default {
    props: ['label', 'modelValue', 'format', 'prependInnerIcon', 'appendInnerIcon','disabled'],
    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value.format())
            }
        }
    },
    data: () => ({
        date: null,
        dateFormatted: null,
        open: false,
        prependInnerIconData: false,
        appendInnerIconData: false,
    }),

    created() {
        if(this.modelValue){
            this.date = moment(this.modelValue).toDate();
        }
        this.prependInnerIconData = this.prependInnerIcon
        this.appendInnerIconData = this.appendInnerIcon

        if(typeof this.prependInnerIconData == 'undefined' &&  typeof this.appendInnerIconData == 'undefined') {
            this.prependInnerIconData = "fas fa-calendar"
        }
    },

    methods: {
        formatDate (date) {
            if (!date) return null
            return moment(date).format(this.format ?? 'DD/MM/YYYY')
        },
        parseDate (date) {
            if (!date) return null
            return moment(date)
        },

        update() {
            this.value = this.parseDate(this.date);
        }
    },
    watch: {
        date (val) {
            this.dateFormatted = this.formatDate(this.date)


        },
        dateFormatted(val) {
            this.update();
        }
    },
}

</script>
