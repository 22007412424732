
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';
import {filterState, questionsConditionalAnswerFilter, questionsOrder} from '../helpers/FilterWrapper';
import { useAppStore } from './app';

const initialState = {
    assetTypes: [],
}

export const useAssetTypesStore = defineStore('assetTypes', {
    persistence: true,
    state: () => ( initialState),
    getters: {
        getAssetTypesById: (state) => {
            return (assetTypeId) => {
                return state.assetTypes.find((assetType) => assetType.id === assetTypeId)
            }
        },
        getAssetTypes: (state) => {
            return (fuzzyText) => {
                return filterState(state.assetTypes).deletedFilter().fuzzyFilter(fuzzyText).sortAsc('name').get();
            }
        },
        getQuestionsByAssetTypeId: (state) => {
            return (assetTypeId) => {
                return questionsOrder(questionsConditionalAnswerFilter(state.assetTypes.find((assetType) => assetType.id === assetTypeId)?.questions))
            }
        }
    },
    actions: {
        async sync() {
            await fetchWrapper.sync(`/asset-types/index`,`/asset-types/update-or-create` ,{
                stored: this.assetTypes.map(function (assetType) { return {id:assetType.id, updated_at: assetType.updated_at}}),
            }, this.assetTypes);
        },

        saveAssetType(assetType) {
            let assetTypeIndex = this.$state.assetTypes.findIndex((assetTypeToFind) => assetTypeToFind.id === assetType.id)
            assetType.updated_at = moment().format()
            if(this.assetTypes[assetTypeIndex] ) {
                this.assetTypes[assetTypeIndex] = assetType
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully updated Asset Type")
            } else {
                this.assetTypes.push(assetType)
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully created Asset Type")
            }
        }
    }
})
