<template>
    <v-card :title="'Scope'">
        <v-card-text>
            <v-row>
                <v-col cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-clipboard"></v-icon-primary-red> <strong>{{scope}}</strong></v-col>
                <v-col v-for="(asset_type,key) in asset_types" :key="key" cols="12" class="d-flex align-center"><div><v-icon-primary-red class="mr-3" icon="far fa-fire-extinguisher"></v-icon-primary-red>{{asset_type.name}} </div> <v-btn-outline :to="{name:'Inspection-Assets', params: {inspection_id: id, asset_type_id: asset_type.id}}" class="ms-auto" v-bind="props" border="0" icon="far fa-arrow-right" size="x-small"></v-btn-outline></v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script setup>
import { defineProps } from 'vue'
import { storeToRefs } from 'pinia';
import { useInspectionStore } from '@/stores/inspection.js';
import { useUserStore } from '@/stores/user';

const inspectionStore = useInspectionStore()
const userStore = useUserStore()
const {inspectors_names, date_range} =  inspectionStore
const {id,scope, asset_types} = storeToRefs(inspectionStore)
const {isAdmin} =  userStore

</script>
