<script>
import  { qrCode }  from '@/helpers/QrScannerWrapper';
import { ref } from 'vue'


export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    mounted: function() {


    },
        computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        setResults(result) {

            this.cameraResults = result
            this.value = this.cameraResults.data
            this.qrCodeScanner.stop();
            this.showButton = true
        },
        startQrCodeScanner() {
            this.showButton = false

            if(!this.qrCodeScanner){
                this.qrCodeScanner = qrCode.init(this.$refs.qrFeed, this.setResults)
            }
            this.qrCodeScanner.start()
        }
    },


    beforeUnmount() {
        if(this.qrCodeScanner) {
            this.qrCodeScanner.stop()
        }
    },

    setup() {


        return {
            cameraResults: ref(),
            stateToFind: ref(),
            qrCodeScanner: null,
            showButton: ref(true),

        }
    },


}
</script>

<template>

    <div class="mb-4">
    <template v-if="showButton">
        <v-btn @click="startQrCodeScanner" class="w-100" prepend-icon="fa-solid fa-qrcode">{{ this.value ? 'Replace QR Code': ('Scan and Allocate QR Code' ?? 'Scan Qr code')}}</v-btn>
    </template>
    <video v-show="!showButton" class="w-100" ref="qrFeed"></video>
    </div>
</template>
