<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useSiteStore } from '../stores/site.js';
import {useRoute} from 'vue-router'


export default {
    setup() {
        const siteStore = useSiteStore()
        const site = storeToRefs(siteStore);


        // expose the ref to the template
        return {
            ...site,
            siteStore,
            route: useRoute()
        }
    },


}
</script>

<template>
    <v-container id="site">
        <v-row>
            <v-col cols="12">
                <v-text-field label="Site name *" required v-model="name"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Client name *" required v-model="client_name"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Address Line 1 *" required v-model="address_line_1"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Address Line 2" v-model="address_line_2"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Town/City *" required v-model="town"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Postcode *" required v-model="post_code"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-textarea label="Notes" v-model="notes"></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-textarea label="Events Log" v-model="events_log"></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-btn @click="siteStore.saveSite()" class="w-100" prepend-icon="fa-solid fa-save">{{route.params?.site_id ? "Save Changes" : "Save Site"}}</v-btn>
            </v-col>
        </v-row>
    </v-container>

</template>
