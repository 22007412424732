<script setup>
import { storeToRefs } from 'pinia';
import { computed, ref , reactive, onUnmounted} from 'vue'
import { v4 as uuidv4 } from 'uuid';
import { useAssetTypesStore } from '../stores/assetTypes.js';
import { useAssetStore } from '../stores/asset.js';
import { useSurveyAnswersStore } from '../stores/surveyAnswers.js';
import { useAssetGradeStore, rules } from '../stores/AssetGrade.js';
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment';
import { useVuelidate } from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { useAssetGradesStore } from '../stores/AssetGrades.js';
import { useInspectionStore } from '../stores/inspection.js';

const assetTypesStore = useAssetTypesStore()
const surveyAnswersStore = useSurveyAnswersStore()
const assetGradesStore = useAssetGradesStore()
const assetGradeStore = useAssetGradeStore()
const inspectionStore = useInspectionStore()
const route = useRoute()
const route_inspection_id = route.params.inspection_id ?? null

if(route.params.asset_grade_id) {
    const surveyAnswers = useSurveyAnswersStore()
    surveyAnswers.initViaAssetGradeId(route.params.asset_grade_id)
    const assetGradeStore = useAssetGradeStore()
    assetGradeStore.initViaAssetGradeId(route.params.asset_grade_id)
}

onUnmounted(() => {
    assetGradeStore.$reset();
    assetGradeStore.$state.id = uuidv4();
})


// const assetGrade = storeToRefs(assetGradeStore);
const assetGrade = storeToRefs(assetGradeStore);
const {recommendations, graded_at, grade} = assetGrade;
const assetStore = useAssetStore()
const asset = storeToRefs(assetStore);
const asset_id = asset?.id?.value;
const now = moment()
const v$ = useVuelidate(rules(assetGradeStore.$state,route), assetGrade)
const snackbar = reactive({show: false})
const loading = reactive({value:false});
const questions = computed(() => {return assetTypesStore.getQuestionsByAssetTypeId(asset.asset_type_id.value)})
const latestAssetGradeForAssetId = computed( () => {return assetGradesStore.getLatestAssetGradeForAssetId(asset.id)})

</script>

<template>
    <v-container id="site">
        <v-form fast-fail @submit.prevent=" assetGradeStore.saveAssetGrade(v$, asset.id, route_inspection_id, snackbar, loading)">
            <v-row>
                <v-col cols="12">
                    <h3>Survey date: {{now.format('DD/MM/YYYY')}}</h3>
                </v-col>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-row>
                            <v-col cols="12">
                            <h3>Grade: *</h3>
                            </v-col>
                            <v-col cols="6" class="offset-3">
                                <v-radio-group class="inside-lable" :disabled="inspectionStore.closed_at" inline v-model="grade" :error-messages="v$.grade.$errors.map(e => e.$message)" @input="v$.grade.$touch" @blur="v$.grade.$touch">
                                    <v-grade-green-radio value="pass" class="mr-5" label="Pass"></v-grade-green-radio>
                                    <v-grade-red-radio value="fail" label="Fail"></v-grade-red-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" >
                                <date-picker :disabled="inspectionStore.closed_at" append-inner-icon="far fa-calendar" label="Date" v-model="graded_at" :error-messages="v$.graded_at.$errors.map(e => e.$message)" @input="v$.graded_at.$touch" @blur="v$.graded_at.$touch" ></date-picker>
                            </v-col>
                            <v-col cols="12" >
                                <v-textarea :disabled="inspectionStore.closed_at" v-model="recommendations" class="text-black"  :hint="'Leave blank if there are no recommendations. If there are no changes, and the previous recommendation still stands, please enter it again.'" label="Recommended remedial works:" :error-messages="v$.recommendations.$errors.map(e => e.$message)" @input="v$.recommendations.$touch" @blur="v$.recommendations.$touch" />
                            </v-col>


                            <v-col cols="12" v-if="latestAssetGradeForAssetId?.recommendations">
                                <v-textarea :disabled="true" v-model="latestAssetGradeForAssetId.recommendations" class="text-black" :label="'Most recent recommendation:'+moment(latestAssetGradeForAssetId.graded_at).format('(DD/MM/YYYY)')" />
                            </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-btn :loading="loading.value" :disabled="inspectionStore.closed_at" type="submit" block prepend-icon="fas fa-circle-check">Submit Survey</v-btn>
                </v-col>
                <v-col cols="12" v-for="(question,index) in   questions"  :key="'question'+index" >

                    <v-card :id="'question-card-' + index">
                        <v-card-text>
                            <v-row>
                                <v-col cols="10">
                                    <h3>Q{{index + 1}}: {{question.name}}:</h3>
                                </v-col>
                                <v-col cols="2" class="py-0">
                                <v-bottom-sheet v-if="inspectionStore.closed_at == null" scroll-strategy="none">
                                    <template v-slot:activator="{ props }">
                                        <v-btn-outline v-bind="props" border="0" icon="far fa-eye"></v-btn-outline>
                                    </template>

                                    <v-card >
                                        <v-card-text>
                                            <h3>Q{{index + 1}}: {{question.name}}:</h3>
                                            <template v-for="(answer_type,index) in   question.answer_types"  :key="'container'+index" >
                                                <v-lazy>
                                                    <v-row>
                                                        <component :answer="surveyAnswersStore.getAnswerByQuestionIdAndAnswerId(question.id, answer_type.id)"  :surveyAnswersStore="surveyAnswersStore.getAnswerByQuestionIdAndAnswerId(question.id, answer_type.id) ? surveyAnswersStore : undefined" :is="answer_type.component_name" v-bind="JSON.parse(answer_type.props)" :question="question" :answerTypeId="answer_type.id"/>
                                                    </v-row>
                                                </v-lazy>
                                            </template>
                                        </v-card-text>
                                    </v-card>
                                </v-bottom-sheet>
                                </v-col>
                            </v-row>
                            <template v-for="(answer_type,index) in   question.answer_types"  :key="'container'+index" >
                                <v-lazy>

                                    <v-row>
                                        <component  :disabled="true"  :answer="surveyAnswersStore.getAnswerByQuestionIdAndAnswerId(question.id, answer_type.id)" :surveyAnswersStore="surveyAnswersStore" :is="answer_type.component_name" v-bind="JSON.parse(answer_type.props)" :key="'componet' + surveyAnswersStore.getAnswerByQuestionIdAndAnswerId(question.id, answer_type.id)?.value" :question="question" :answerTypeId="answer_type.id"/>
                                    </v-row>
                                </v-lazy>
                            </template>
                        </v-card-text>
                    </v-card>


                </v-col>

                <v-col cols="12" v-if="assetGrade?.updated_at">

                    <v-bottom-sheet >
                        <template v-slot:activator="{ props }">
                            <v-btn block color="primaryRed"  v-bind="props" class="bg-white" prepend-icon="fa-solid fa-trash">Delete Survey</v-btn>
                        </template>

                        <v-card >
                            <v-card-text>
                                <h3 class="mb-3">Are you sure you want to delete this Survey?</h3>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn class="w-100" rounded="rounded" @click="assetGradesStore.deleteById(assetGrade.id.value)" prepend-icon="fa-solid fa-trash">Yes</v-btn>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                            </v-card>
                    </v-bottom-sheet>
                </v-col>
            </v-row>
        </v-form>
    </v-container>

</template>
