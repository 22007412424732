<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useSitesStore } from '../stores/sites.js';
import { useAssetTypesStore } from '../stores/assetTypes.js';
import { useUsersStore } from '../stores/users.js';
import { useAssetStore, rules } from '../stores/asset.js';
import QrScanner from '@/components/QRScanner.vue'

import {useRoute} from 'vue-router';
import { useVuelidate } from '@vuelidate/core'
import QRScanner from '../components/QRScanner.vue';


export default {
    components: {
        QrScanner
    },
    methods: {
        formatAssetName(asset) {
            const name = asset.name;
            const maxCharsPerLine = 15;
            let formattedName = '';

            for (let i = 0; i < name.length; i += maxCharsPerLine) {
                formattedName += name.substring(i, i + maxCharsPerLine) + '\n';
            }

            return formattedName.trim();
        },

        setSite(value) {
            this.site = value;
            this.site_id = value?.id
        },

        setAsset(value) {
            this.asset_type = value;
            this.asset_type_id = value?.id
        }
    },
    mounted() {
        if(this.site_id) {
            this.site = this.sitesStore.getSiteById(this.site_id)
        }

        if(this.route?.params?.site_id) {
            this.site_id = this.route?.params?.site_id
            this.site = this.sitesStore.getSiteById(this.route?.params?.site_id)
        }

    },
    setup() {
        const route = useRoute()
        const sitesStore = useSitesStore()
        const assetTypesStore = useAssetTypesStore()
        const usersStore = useUsersStore()
        const assetStore = useAssetStore()
        const asset = storeToRefs(assetStore);
        const v$ = useVuelidate(rules(assetStore.$state,route), asset)

        // expose the ref to the template
        return {
            ...asset,
            sitesStore,
            assetTypesStore,
            assetStore,
            usersStore,
            route,
            v$
        }
    },
}
</script>

<template>
    <v-container id="site">
        <v-form fast-fail @submit.prevent="assetStore.saveAsset(v$)">
            <v-row>
                <v-col cols="12">
                    <v-select
                        item-title="name"
                        item-value="id"
                        :model-value="asset_type"
                        @update:modelValue="setAsset"
                        required
                        return-object
                        label="Type *"
                        :error-messages="v$.asset_type.$errors.map(e => e.$message)"
                        @input="v$.asset_type.$touch"
                        @blur="v$.asset_type.$touch"
                        :items="assetTypesStore.getAssetTypes()">
                        item-class="long-name-option"
                    </v-select>
                </v-col>
                <v-col cols="12">
                    <v-select
                        :error-messages="v$.site.$errors.map(e => e.$message)" @input="v$.site.$touch" @blur="v$.site.$touch"
                        :items="sitesStore.getSites()"
                        :model-value="site"
                        @update:modelValue="setSite"
                        item-title="name"
                        item-value="id"
                        required
                        return-object
                        label="Site *"
                    ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Location *" required v-model="location"  :error-messages="v$.location.$errors.map(e => e.$message)" @input="v$.location.$touch" @blur="v$.location.$touch"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <QrScanner v-model="serial_number"></QrScanner>
                </v-col>
                <v-col cols="12">
                    <v-text-field  label="Asset Serial Number" v-model="serial_number"  :error-messages="v$.serial_number.$errors.map(e => e.$message)" @input="v$.serial_number.$touch" @blur="v$.serial_number.$touch"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-btn type="submit" class="w-100" prepend-icon="fa-solid fa-save">{{route.params?.asset_id ? "Save Changes" : "Save Asset"}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>

</template>
