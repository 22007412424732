import { defineStore } from 'pinia'

import { cloneDeep, isEqual } from "lodash";
import { useUsersStore } from './users';
import { useUserStore } from './user';

import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core'
import { email, required, sameAs } from '@vuelidate/validators'
import { uniqueUsername} from '@/helpers/CustomValidators'
import { helpers } from '@vuelidate/validators'

const initialState =  (uuidv4) => { return {
    'id': uuidv4,
    'username': '',
    'forename': '',
    'surname': '',
    'email_address': '',
    'password': '',
    'confirm_password': '',
    'user_role_id': '',
    'status':'',
    'updated_at': null,
    'deleted_at': null
}}

export const rules = (state,route, currentUsername) => { return {
    username: {  required, uniqueUsername: uniqueUsername(currentUsername)},
    forename: { required },
    surname: { required },
    email_address: {  },
    password: { isRequiredIfNew:  helpers.withMessage('Passwords required', function(value) {

        if(!!route?.params?.user_id && !value ) {
            return true
        }
        return !!value
    })},
    confirm_password: { checkPassword: helpers.withMessage('Passwords do not match', function(value) {
        if(!!route?.params?.user_id && !value ) {
            return true
        }

        return state.password == value

    })},
    user_role_id: {  },
    status: {  },
}}

export const useUserModelStore = defineStore('userModel', {
    //As part of my persistence plugin will allow a state to be initialized after a state has been synced from storage
    initByRoute: (route) => {
        const usersStore =  useUsersStore();
        return route.params.user_id ? usersStore.getUserById(route.params.user_id) : cloneDeep(initialState(uuidv4()))
    },
    state: () => ( cloneDeep(initialState(uuidv4()))),
    actions: {
        async saveUser(v$) {
            const isFormCorrect = await v$.$validate()
            if(isFormCorrect){
                const usersStore =  useUsersStore();
                usersStore.save(cloneDeep(this.$state), this.router)
                usersStore.sync();
                this.router.push({name: 'Users'})
            }
        },
    },
})

