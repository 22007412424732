<script setup>
import { defineProps, onMounted, reactive } from 'vue'
import { storeToRefs } from 'pinia';
import { useSiteStore } from '@/stores/site.js';
import { useUserStore } from '@/stores/user';
import { deleteNow } from '../helpers/FilterWrapper.js';
import { fetchWrapper } from '../helpers/fetchWrapper.js';
import moment from 'moment'
import { useAssetGradesStore } from '../stores/AssetGrades.js';
import { useInspectionsStore } from '../stores/inspections';
import { useAssetsStore } from '../stores/assets.js';

const siteStore = useSiteStore()
const assetGradesStore = useAssetGradesStore()
const assetsStore = useAssetsStore()
const site = storeToRefs(siteStore)
const inspectionAssetGradeCounts = reactive([]);
const {id,name, client_name,address,notes} = storeToRefs(siteStore)

const fetchAssetGradeCounts = function() {

};

onMounted( async () => {
    siteStore.inspections.forEach(async (inspection) => {
        inspectionAssetGradeCounts[inspection.id] = await getInspectionAssetGradesCount(inspection) ?? 0
    });
});

const dateRange = function (date_from, date_to) {
    const date_from_formatted = moment(date_from).format('DD/MM/YYYY');
    const date_to_formatted = moment(date_to).format('DD/MM/YYYY');
    return date_from_formatted == date_to_formatted ? date_from_formatted : date_from_formatted + ' - ' + date_to_formatted;
}

const getInspectionAssetGradesCount = async (inspection) => {
    const count = await fetchWrapper.post(`/inspections/${inspection.id}/get-asset-grade-count`);
    console.log(count);
    return count;
}

const getInspectionAssets = (inspection) => {
    const assetTypeIds = inspection.asset_types.map((assetType)=> {  return assetType.id })
    return assetsStore.getAssetsBySiteIdAndInspectionAssetTypes(inspection.site_id, assetTypeIds);
}

</script>
<template>
    <v-card :title="'Inspection History'">
        <v-card-text>
            <v-row >
                <v-col cols="6">
                    <v-btn block prepend-icon="fas fa-clipboard-list-check" :to="{name:'Inspection-Create', params: {site_id: id}}">New Inspection</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn  block prepend-icon="fas fa-clipboard-list-check" :to="{name:'Inspections', params: {site_id: id}}"> View Inspections</v-btn>
                </v-col>
                <v-col cols="12" v-for="(inspection,index) in siteStore.inspections" :key="inspection.id+'-'+index">
                    <v-card  @click="value = false" color="primary" rounded="rounded-lg" :to="{name: 'Inspection', params: {inspection_id: inspection.id}}">
                        <v-card-text>
                            <v-row class="align-center">
                                <v-col cols="10">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="flex-shrink-1"><strong>{{dateRange(inspection.date_from, inspection.date_to)}}</strong></v-col>
                                        <v-col cols="12">{{inspection.title}}</v-col>
                                        <v-col cols="12">{{inspectionAssetGradeCounts[inspection.id]}}/{{getInspectionAssets(inspection).length}}</v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="2" class="flex-shrink-1"><v-icon icon="far fa-arrow-right"/></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
