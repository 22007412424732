<script setup>
import {storeToRefs} from 'pinia';
import {ref} from 'vue'
import InspectionPanel from '@/components/InspectionPanel.vue'
import ScopePanel from '@/components/ScopePanel.vue'
import LocationPanel from '@/components/LocationPanel.vue'
import StatisticsReportsPanel from '@/components/StatisticsReportsPanel.vue'
import FindAsset from '@/components/FindAsset.vue';
import FindAssetBottomSheetVue from '@/components/FindAssetBottomSheet.vue';
// import { useUsersStore } from '../stores/users.js';
import {useInspectionStore} from '../stores/inspection.js';
import { useUserStore } from '../stores/user.js';
import { useInspectionsStore } from '../stores/inspections.js';

const inspectionStore = useInspectionStore()
const inspection = storeToRefs(inspectionStore);
const showFindAsset = ref(false)
const userStore = useUserStore();
const inspectionsStore = useInspectionsStore();

</script>

<template>
    <v-row class="mt-3">
        <v-col  cols="12">
            <v-container>
                <v-row>
                    <v-col  cols="12">
                        <find-asset></find-asset>
                    </v-col>
                    <v-col cols="6">
                        <v-bottom-sheet v-model="showFindAsset" height="50vh">
                            <template v-slot:activator="{ props }">
                                <v-btn block v-bind="props"  prepend-icon="far fa-search">Find Asset</v-btn>
                            </template>
                            <v-card color="#EAEAEA" height="100%">
                                <v-card-text>
                                    <FindAssetBottomSheetVue  @update:modelValue="showFindAsset = false" :siteId="inspection.site_id"></FindAssetBottomSheetVue>
                                </v-card-text>
                            </v-card>
                        </v-bottom-sheet>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block :to="{name:'Asset-Create', params: {site_id: inspection.site_id.value}}" prepend-icon="fas fa-circle-plus">Add Asset</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col cols="12" class="mt-3">
            <InspectionPanel></InspectionPanel>
        </v-col>
        <v-col cols="12" class="mt-3">
            <ScopePanel></ScopePanel>
        </v-col>
        <v-col cols="12" class="mt-3">
            <LocationPanel></LocationPanel>
        </v-col>
        <v-col cols="12" class="mt-3">
            <StatisticsReportsPanel></StatisticsReportsPanel>
        </v-col>
        <v-col cols="12" class="mt-3" v-if="userStore.isAdmin()">
            <div class="px-3 pb-3">

                <v-bottom-sheet >
                    <template v-slot:activator="{ props }">
                        <v-btn block color="primaryRed" v-bind="props" class="bg-white" prepend-icon="fa-solid fa-trash">Delete Inspection</v-btn>
                    </template>

                            <v-card >
                                <v-card-text>
                                    <h3 class="mb-3">Are you sure you want to delete this inspection?</h3>
                                    <v-row>
                                        <v-col cols="12">
                                          <v-btn class="w-100" rounded="rounded" @click="inspectionsStore.deleteById(inspection.id.value)" prepend-icon="fa-solid fa-trash">Yes</v-btn>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                    </v-bottom-sheet>
            </div>
        </v-col>
    </v-row>
</template>
