<script setup>
import { storeToRefs } from 'pinia';
import { ref, defineAsyncComponent } from 'vue'
import SitePanel from '@/components/SitePanel.vue'
import SiteInspectionPanel from '@/components/SiteInspectionPanel.vue'
import EventsLogPanel from '@/components/EventsLogPanel.vue'
const AssetGradingPanel = defineAsyncComponent( () =>import('../components/AssetGradingPanel.vue') )
</script>

<template>
    <v-row class="mt-3">
        <v-col cols="12">
            <SitePanel></SitePanel>
        </v-col>
        <v-col cols="12">
            <EventsLogPanel></EventsLogPanel>
        </v-col>
        <v-col cols="12">
            <SiteInspectionPanel></SiteInspectionPanel>
        </v-col>
        <v-col cols="12">
            <AssetGradingPanel></AssetGradingPanel>
        </v-col>
    </v-row>
</template>
