<template>
    <div>
        <v-card :title="name">
            <v-card-text class="site-report-text">
                <v-row>
                    <v-col v-if="client_name" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-user"></v-icon-primary-red> <span>{{client_name}}</span></v-col>
                    <v-col v-if="address" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-map-location"></v-icon-primary-red><span>{{address}}</span></v-col>
                    <v-col v-if="notes" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-note"></v-icon-primary-red><span>{{notes}}</span></v-col>
                    <v-col cols="6" class="v-btn-padding">
                        <v-btn-outline class="w-100" prepend-icon="fas fa-edit" :to="{ name: 'Site-Edit', params: { site_id: id }}">Edit Site</v-btn-outline>
                    </v-col>
                    <v-col cols="6" class="v-btn-padding">
                        <v-btn-outline class="w-100" color="primaryRed"  @click="deleteSite" prepend-icon="fas fa-trash">Delete Site</v-btn-outline>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row class="mt-3 px-3">
            <v-col v-if="assetTypes.length !== 0"  cols="12" >
                <v-btn class="w-100" @click="openBottomSheet()" prepend-icon="fas fa-download">
                    Download Site Report...
                </v-btn>
            </v-col>
            <v-bottom-sheet v-model="bottomSheet">
                <v-card>
                    <form  class="w-100" :action="'/site/report/' + id" @submit.prevent="onSubmit" >
                        <v-card-text >
                            <!-- <iframe v-if="iframeUrl" :src="iframeUrl"> </iframe> -->
                            <div class="pl-2">
                                <h3>Download Site Report...</h3>
                                <p>Which asset type(s) would you like to include?</p>
                            </div>
                            <v-row no-gutters>
                                <v-col v-for="(type) in assetTypes" :key="type.id" cols="12">
                                    <v-checkbox hide-details :value="type.id" name="asset_types[]" :label="type.name"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-btn :loading="submitting" class="w-100 "  color="primary" prepend-icon="fas fa-download" type="submit">
                                Download Site Report
                            </v-btn>
                        </v-card-text>
                    </form>
                </v-card>
            </v-bottom-sheet>
        </v-row>
    </div>
</template>

<script setup>

import {onMounted, ref} from 'vue';
import {storeToRefs} from 'pinia';
import {useSiteStore} from '@/stores/site.js';
import {useUserStore} from '@/stores/user';
import {deleteNow} from '../helpers/FilterWrapper.js';
import { useAppStore } from '../stores/app.js';
import { useSitesStore } from '../stores/sites.js';
import { useAssetsStore } from "../stores/assets.js";
import axios from 'axios';

const siteStore = useSiteStore();
const userStore = useUserStore();

const { id, name, client_name, address, notes } = storeToRefs(useSiteStore());
const { isAdmin } = useUserStore();

const assetsStore = useAssetsStore();
const sitesStore = useSitesStore()
const site = storeToRefs(siteStore)

const deleteSite = function () {
    const siteId = siteStore.$state.id
    const appStore = useAppStore()
    appStore.setSnackbar("Successfully deleted site", function () {
        const sitesStore = useSitesStore();
        sitesStore.undoDelete(siteId)
    })
    sitesStore.deleteSite(siteId)
}

const bottomSheet = ref(false);
const assetTypes = ref([]);
const selectedAssetTypes = ref([]);
const iframeUrl = ref('');

const fetchAssetTypes = async () => {
    const siteAssetTypesMap = assetsStore.getUniqueAssetTypesBySiteId(id.value);
    selectedAssetTypes.value = siteAssetTypesMap.map(() => false);
    assetTypes.value = siteAssetTypesMap;
    bottomSheet.value = false;
};

const openBottomSheet = () => {
    bottomSheet.value = true;
};

const submitting = ref(false)
const onSubmit = async function (event) {
    event.preventDefault();
    submitting.value = true

    let formData = new FormData(event.target, event.submitter);

    let search = new URLSearchParams(formData);
    let queryString = search.toString();
      var xhr = new XMLHttpRequest();
      xhr.open('GET', event.target.action  + '?' + queryString, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(myBlob);
          link.download = "site-report.pdf";
          link.click();
          submitting.value = false
        }
      };
      xhr.send();

};

onMounted(fetchAssetTypes);
</script>
