
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';

import { cloneDeep, isEqual } from "lodash";
import { useAssetsStore } from './assets';
import { useUserStore } from './user';

import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';

import { helpers } from '@vuelidate/validators'
import { email, required, sameAs } from '@vuelidate/validators'
import { formToJSON } from 'axios';
import moment from 'moment';
import { useAssetGradeStore } from './AssetGrade';


const initialState = (uuidv4, site, asset_type) => {
    const userStore =   useUserStore();
    return {

    'id': uuidv4,
    'created_by_id': userStore.user_id,

    'asset_type_id': asset_type?.id ?? null,
    'asset_type': asset_type ?? null,
    'site': site ?? null,
    'site_id': site?.id ?? null,
    'location': null,
    'serial_number': null,
    'history': null,
    'created_at': null,
    'updated_at': null,
    }
}

export const rules = (state,route) => { return {
    'created_by_id': {},
    'asset_type_id': {required},
    'asset_type': {required},
    'site': {required},
    'site_id': {required},
    'location': {required},
    'serial_number': {},
    'history': {},
    'created_at': {},
    'updated_at': {},
  }}

export const useAssetStore = defineStore('asset', {
    //As part of my persistence plugin will allow a state to be initialized after a state has been synced from storage
    initByRoute: (route) => {
        const assetsStore =  useAssetsStore();
        const assetStore = useAssetStore();

        return route.params.asset_id ? assetsStore.getAssetById(route.params.asset_id) : cloneDeep(initialState(uuidv4(), assetStore.$state.site, assetStore.$state.asset_type))
    },
    state: () => ( cloneDeep(initialState(uuidv4()))),
    actions: {
        async saveAsset(v$) {
            const isFormCorrect = await v$.$validate()
            if(isFormCorrect){
                const assetsStore =  useAssetsStore();
                assetsStore.saveAsset(cloneDeep(this.$state), this.router)
                this.router.push({name: 'Asset-Overview', params: { asset_id: this.$state.id}});
            }
        },
        async saveAssetHistory(v$) {
            const assetsStore =  useAssetsStore();
            assetsStore.saveAsset(cloneDeep(this.$state), this.router)
        },

        async saveRepairAsset() {
            const userStore = useUserStore()
            const assetGrade = useAssetGradeStore()
            this.$state.history =  (this.$state.history ? this.$state.history +'\n': '') + moment().format('DD/MM/YYYY') + " Asset repaired by " + userStore.name + '\n'

            assetGrade.saveRepairReplaceAssetGrade(this.$state.id)

            const assetsStore =  useAssetsStore();
            assetsStore.saveAsset(cloneDeep(this.$state), this.router)
        },
        async saveReplaceAsset() {
            const userStore = useUserStore()
            const assetGrade = useAssetGradeStore()
            this.$state.history =     (this.$state.history ? this.$state.history +'\n': '') +  moment().format('DD/MM/YYYY') + " Asset replaced by " + userStore.name + '\n'

            assetGrade.saveRepairReplaceAssetGrade(this.$state.id)

            const assetsStore =  useAssetsStore();
            assetsStore.saveAsset(cloneDeep(this.$state), this.router)


        }
    },
})

